/**
 * Creation Date: 25/02/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetAllProducts } from "../lib/database";
import CardProduct from "../components/productpage/CardProduct";
import ProductListShow from "../components/categoriapage/ProductListShow";
import Loader from "../components/Loading";
import Footer from "../components/mainpage/Footer";

export default function Search() {

    const [products, setProducts] = useState(null);
    const [pesquisa, SetPesquisa] = useState(null);
    const [Loading, setLoading] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);


    useEffect(() => {
        urlParams.forEach((i) => i && SetPesquisa(i.toLowerCase()))
        pullProducts()
    }, [urlParams])


    async function pullProducts() {
        setLoading(true);
        const data = await GetAllProducts();
        const filteredProducts = data.filter(item => item.name.toLowerCase().includes(pesquisa))
        setProducts(filteredProducts);
        setLoading(false);
    }

    return (
        <div className="SearchPageContent">
            <ProductListShow
                type="Pesquisa"
                products={products || []}
            />
            :
            <>
                {Loading && !products &&
                    <Loader />
                }
            </>
            <Footer />
        </div>
    )
}