/**
 * Creation Date: 26/04/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import { useEffect, useState } from "react";
import { account, databases } from "../../../lib/appwrite";
import Navigation from "../../../components/accounts/Navigation";
import Loader from "../../../components/Loading";
import { auth } from "../../../lib/firebase";
import { getCurrentUser } from "../../../lib/database";

export default function MyAccount() {
    const [userLogged, setUserLogged] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    const data = await getCurrentUser(user.uid);
                    setUserLogged(data)
                } catch (error) {
                    console.warn("Erro ao obter dados do usuário: ", error);
                } finally {
                    setLoading(false);
                }
            } else {
                console.warn("Usuário não autenticado");
                setLoading(false);
            }
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        document.title = "COM TODO RESPEITO: Minha conta";
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (
        <section className="MyAccount-Show-Page-styled">
            <Navigation />
            <main className="MainPageSelected">
                <h1>Dados pessoais</h1>
                <p>Gerencie as configurações e os dados da sua conta.</p>
                <div className="Account-MainPageSelected-Form-Container">
                    {userLogged ? (
                        <>
                            <div className="Divisions-Inputbox-Name">
                                <h4>Nome</h4>
                                <p>{userLogged.nome_completo}</p>
                            </div>
                            <div className="Divisions-Inputbox-Name">
                                <h4>E-mail</h4>
                                <p>{userLogged.email}</p>
                            </div>
                            <div className="Divisions-Inputbox-Name">
                                <h4>Telefone</h4>
                                <p>{userLogged.telefone}</p>
                            </div>
                            <div className="Divisions-Inputbox-Name">
                                <h4>CPF</h4>
                                <p>{userLogged.cpf}</p>
                            </div>
                        </>
                    ) : (
                        <p>Usuário não encontrado.</p>
                    )}
                </div>
            </main>
        </section>
    );
}