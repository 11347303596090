/**
 * Creation Date: 25/02/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Changed by: Vinícius da Silva Santos
 * Changed date: 18/06/2024
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import MainPage from './pages/MainPage.jsx';
import Header from './components/Header.js';
import Camisetas from './pages/Categorias/Camisetas.jsx';
import Moletons from './pages/Categorias/Moletons.jsx';
import Calcas from './pages/Categorias/Calcas.jsx';
import Bones from './pages/Categorias/Bones.jsx';
import ProdutoPage from './pages/ProdutoPage.jsx';
import Novidades from './pages/Categorias/Novidades.jsx';
import Register from './pages/Auth/Register.jsx';
import Login from './pages/Auth/Login.jsx';
import MyAccount from './pages/Auth/MyAccount/MyAccount.jsx';
import Checkout from './pages/Checkout.jsx';
import MyAccount_Adress from './pages/Auth/MyAccount/MyAccountAdress.jsx';
import Search from './pages/Search.jsx';
import MyAccount_Orders from './pages/Auth/MyAccount/MyAccount_Orders.jsx';
import SucessPage from './pages/Sucess.jsx';
import PoliticaDevolucao from './pages/Institucional/Politicas/PoliticaDevolucao.jsx';
import PoliticaPrivacidade from './pages/Institucional/Politicas/PoliticaPrivacidade.jsx';
import Depoimentos from './pages/Institucional/Depoimentos.jsx';
import QuemSomos from './pages/Institucional/QuemSomos.jsx';
import MyAccount_Fav from './pages/Auth/MyAccount/MyAccount_Fav.jsx';
import MyAccount_Help from './pages/Auth/MyAccount/MyAccount_Help.jsx';
import CortaVentos from './pages/Categorias/Corta-Ventos.jsx';
import AdminPage from './pages/Admin/AdminPage.jsx';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <div className='Application-Content'>
          <Routes>
            <Route path='/' element={<MainPage />} />

            <Route path='/accounts/register' element={<Register />} />
            <Route path='/accounts/signin' element={<Login />} />

            <Route path='/accounts/myaccount' element={<MyAccount />} />
            <Route path='/accounts/myaccount/orders' element={<MyAccount_Orders />} />
            <Route path='/accounts/myaccount/address' element={<MyAccount_Adress />} />
            <Route path="/accounts/myaccount/favorites" element={<MyAccount_Fav />} />
            <Route path="/accounts/myaccount/help" element={<MyAccount_Help />} />

            <Route path='/orders/sucess' element={<SucessPage />} />
    
            <Route path='/search' element={<Search />} />

            <Route path='/camisetas' element={<Camisetas />} />
            <Route path='/moletons' element={<Moletons />} />
            <Route path='/corta-ventos' element={<CortaVentos />} />
            <Route path='/bones' element={<Bones />} />
            <Route path='/calcas' element={<Calcas />} />
            <Route path='/novidades' element={<Novidades />} />

            <Route path='/quem-somos' element={<QuemSomos />} />
            <Route path='/depoimentos-clientes' element={<Depoimentos />} />
            <Route path='/institucional/policia-devolucao' element={<PoliticaDevolucao />} />
            <Route path='/institucional/policia-privacidade' element={<PoliticaPrivacidade />} />

            <Route path='/checkout' element={<Checkout />} />

            <Route path='/produtos/:id/:url' element={<ProdutoPage />} />

            <Route path='/admin' element={<AdminPage />} />
            <Route path='/admin/login' element={<MainPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
