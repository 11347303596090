/**
 * Creation Date: 22/08/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import React from "react"
import { Link } from "react-router-dom"
import LogoHeader from '../../images/logo-header.webp';
import '../../style/navigation.css'

const Redirects = [
    {
        id: 1,
        url: window.location.origin + "/admin",
        name: "Dashboard",
        icon: <i className="fa-solid fa-house"></i>
    },
    {
        id: 2,
        url: window.location.origin + "/admin/products",
        name: "Produtos",
        icon: <i className="fa-solid fa-shirt"></i>
    },
    {
        id: 3,
        url: window.location.origin + "/admin/clients",
        name: "Clientes",
        icon: <i className="fa-solid fa-user"></i>
    },
    {
        id: 4,
        url: window.location.origin + "/admin/management-money",
        name: "Financeiro",
        icon: <i className="fa-solid fa-money-bill"></i>
    },
    {
        id: 5,
        url: window.location.origin + "/admin/orders",
        name: "Pedidos",
        icon: <i className="fa-solid fa-box"></i>
    },

]

const NavigaitonBar = ({ CurrentUser }) => {
    return (
        <React.Fragment>
            <header className="NavigationBar">
                <div className="LogoSide">
                    <img src={LogoHeader} alt="Logo" />
                </div>
                <div className="MiddleSide">
                    <img src={CurrentUser && CurrentUser.photoURL} alt="Profile Image" />
                    <div className="informationSide">
                        <h2>{CurrentUser && CurrentUser.nome_completo}</h2>
                        <p>{CurrentUser && CurrentUser.role}</p>
                    </div>
                </div>
                <div className="NavigationOptions">

                    <h3>Opções de Navegação</h3>

                    {Redirects.map((item) => {
                        return (
                            <Link reloadDocument
                                className="RedirectLink"
                                id={window.location.href == item.url && "navselected"}
                                to={item.url}
                                key={item.id}
                            >
                                <div className="btnOption">
                                    <span>{item.icon} {item.name}</span>
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </header>
        </React.Fragment>
    )
}

export default NavigaitonBar;