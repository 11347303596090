/**
 * Creation Date: 26/04/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { account, databases } from "../../../lib/appwrite";
import Navigation from "../../../components/accounts/Navigation";
import Loader from "../../../components/Loading";
import { getCurrentUser, getFavoritesUser } from "../../../lib/database";
import CardProduct from "../../../components/productpage/CardProduct";
import { auth } from "../../../lib/firebase";

export default function MyAccount_Fav() {

    const [userLogged, setUserlogged] = useState(null);
    const [Loading, setLoading] = useState(false);

    const [favorites, setFavorites] = useState([]);

    const getFavorites = async (uid) => {
        try {
            const data = await getFavoritesUser(uid);
            setFavorites(data.array);
        }
        catch (error) {
            console.warn(error);
        }
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    const data = await getCurrentUser(user.uid);
                    setUserlogged(data);
                    getFavorites(data.uid);
                } catch (error) {
                    console.warn("Erro ao obter dados do usuário: ", error);
                } finally {
                    setLoading(false);
                }
            } else {
                console.warn("Usuário não autenticado");
                setLoading(false);
            }
        });
        return () => unsubscribe();
    }, []);

    function c() {
        window.document.title = "COM TODO RESPEITO: Minha conta"
    }

    useEffect(() => {
        c()
    })


    return (
        <>
            {Loading ? <Loader /> : null}
            <section className="MyAccount-Show-Page-styled">
                <Navigation />
                <main className="MainPageSelected">
                    <h1>Favoritos</h1>
                    <p>Veja os produtos que você favoritou.</p>
                    <div className="Container-Focus-MyAccount">
                        {favorites.length > 0 ?
                            <div className="favoritesProducts">
                                {favorites.map((item) => {
                                    return <CardProduct
                                        key={item.id}
                                        product={item}
                                    />
                                })}
                            </div>
                            :
                            <span>Você não tem produtos favoritados. :(</span>}


                    </div>
                </main>
            </section>
        </>
    )
}