/**
 * Creation Date: 17/08/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */


import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyD8HuDx0nUWH19KQqGkMs2vRATfeMb6qK8",
    authDomain: "com-todo-respeito-inc.firebaseapp.com",
    projectId: "com-todo-respeito-inc",
    storageBucket: "com-todo-respeito-inc.appspot.com",
    messagingSenderId: "1893775874",
    appId: "1:1893775874:web:a306ed48ddffa268e6451b",
    measurementId: "G-1W6NV2PRQ6"
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const endpoint = process.env.REACT_APP_API_ENDPOINT;
//const endpoint = process.env.REACT_APP_API_ENDPOINT_TEST;
const secretKey = process.env.REACT_APP_API_SECRET_KEY;

const auth = getAuth();

const CreateNewAccount = async (user) => {
    await createUserWithEmailAndPassword(auth, user.email, user.password)
        .then(async (userCredential) => {
            await fetch(`${endpoint}/api/v1/${secretKey}/users/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    uid: userCredential.user.uid,
                    nome_completo: user.nome_completo,
                    cpf: user.cpf,
                    email: user.email
                }),
            })
                .then((res) => {
                    window.location.href = window.location.origin;
                })
                .catch((err) => console.error(err))
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(error)
        });
}

const loginIn = async (user) => {
    await signInWithEmailAndPassword(auth, user.email, user.password)
        .then(async (userCredential) => {
            window.location.href = window.location.origin
        })
}

const CheckIfUserIsLogged = () => {
    if (auth.currentUser && auth.currentUser.uid && auth.currentUser.email) {
        return true;
    } else {
        return false;
    }
};


export { auth, CreateNewAccount, loginIn, CheckIfUserIsLogged }
