/**
 * Creation Date: 25/04/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import React from "react";
import Footer from "../../components/mainpage/Footer";

const QuemSomos = () => {
    return (
        <React.Fragment>
            <div className="politicas-Card">

                <h1>Sobre Nós - Com Todo Respeito Inc.</h1>
                <p>Bem-vindo à Com Todo Respeito Inc., onde a moda encontra a atitude, e cada peça que você veste é uma declaração de poder. Situada no coração de Pouso Alegre, Minas Gerais, somos mais do que uma loja virtual; somos um movimento, um estilo de vida, um manifesto vestível que celebra a individualidade e o poder pessoal através do streetwear.</p>

                <h2>Nossa Jornada</h2>
                <p>Nossa aventura começou em 2021, sob o nome VNS-CLOSET, com o sonho de trazer para o mercado brasileiro um novo olhar sobre a moda streetwear. Inspirados pela cultura urbana e pelo desejo de expressar autenticidade e resistência, nos lançamos nessa jornada para criar peças que não apenas vestem o corpo, mas também contam uma história, a sua história.</p>

                <p>Em 2024, renascemos como Com Todo Respeito Inc., um nome que reflete nossa evolução e nosso compromisso em oferecer mais do que roupas; oferecemos uma voz. Uma voz que ecoa nas ruas, nos encontros casuais, nas manifestações artísticas e em cada detalhe do cotidiano urbano.</p>

                <h2>Nosso Foco</h2>
                <p>Especializados em camisetas, moletons, corta-ventos, bonés e calças, nosso foco é claro: proporcionar estilo, conforto e qualidade, mantendo-se fiel ao espírito livre e desafiador do streetwear. Cada peça é pensada e desenvolvida com um propósito, carregando em seu design a essência da força e da coragem, simbolizadas pela nossa marcante logo de coroa. Porque para nós, cada cliente é realeza, merecedor de respeito e digno de portar a coroa que é a nossa marca.</p>

                <h2>Nosso Compromisso</h2>
                <p>Em Com Todo Respeito Inc, cada detalhe é cuidadosamente pensado para garantir que você se sinta poderoso e confiante. Da escolha dos tecidos à última linha na costura, buscamos excelência para que você possa se expressar de forma autêntica. Mais do que seguir tendências, queremos criar um espaço onde a moda se encontra com a mensagem, onde cada produto é um veículo para o empoderamento pessoal.</p>

                <p>Estamos constantemente explorando e expandindo os horizontes do que significa ser uma marca de streetwear no Brasil, comprometidos com a sustentabilidade, a inovação e, acima de tudo, o respeito pelo nosso público. Acreditamos no poder da comunidade e na moda como forma de união e expressão.</p>

                <h2>Conecte-se Conosco</h2>
                <p>A Com Todo Respeito Inc. convida você a ser parte dessa jornada. Explore nossa coleção, conte sua história e descubra o poder da sua própria voz através do nosso streetwear. Estamos aqui para vestir sua rebelião, sua arte, sua coragem.</p>

                <p>Porque no final das contas, é com todo respeito que afirmamos: você merece ser visto, ouvido e, acima de tudo, respeitado.</p>

                <h3>Com Todo Respeito Inc. - Mais do que moda, uma declaração de poder.</h3>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default QuemSomos;