/**
 * Creation Date: 08/04/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */


import React, { useEffect, useState } from "react";
import ProductListShow from "../../components/categoriapage/ProductListShow";
import '../../style/categorias.css';
import { GetAllProducts } from "../../lib/database";
import Loader from "../../components/Loading";
import Footer from "../../components/mainpage/Footer";

const CortaVentos = () => {
    const [AllProducts, setAllProducts] = useState(null);
    const [Loading, setLoading] = useState(false);

    useEffect(() => {
        ProductsRequest();

    }, [])

    async function ProductsRequest() {
        setLoading(true);
        const resp = await GetAllProducts();
        const filteredProducts = resp.filter((item) => item.type === "Corta-Ventos").reverse();
        setAllProducts(filteredProducts);
        setLoading(false);
    }


    return (
        <React.Fragment>
            {AllProducts ?
                <ProductListShow
                    type="Corta-Ventos"
                    products={AllProducts || []}
                />
                :
                <>
                    {Loading &&
                        <Loader />
                    }
                </>
            }
            <Footer />
        </React.Fragment>
    )
}

export default CortaVentos;