/**
 * Creation Date: 22/08/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import { Link } from "react-router-dom";

function WhatsAppRedirect() {
    return (
        <div className="floatButton-Redirect">
            <Link reloadDocument to={"https://wa.me/553599553467"} target="_blank"><img src={"https://cdn-icons-png.flaticon.com/512/3670/3670051.png"} /></Link>
        </div>
    )
}

export default WhatsAppRedirect;