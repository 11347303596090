/**
 * Creation Date: 22/05/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
*/

import { Account, Client, Databases } from 'appwrite';

const client = new Client();

client
    .setEndpoint('https://cloud.appwrite.io/v1')
    .setProject('65de8cae6fe3b1a4f393');
    //.setProject(secretKey);

const databases = new Databases(client);
const account = new Account(client);

const logoutUser = async () => {
    try {
        await account.deleteSession('current');
    }
    catch(error) {
        alert("Não foi possível sair da sua conta, tente novamente mais tarde.");
    }
    finally {
        window.location.href = window.location.origin;
    }
}

export {databases, account, logoutUser};