/**
 * Creation Date: 25/02/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import React, { useEffect } from "react";
import Slider_Banners from "../components/mainpage/Slider_Banners";
import '../style/mainpage.css'
import Categorias from "../components/mainpage/Categorias";
import MediumBanners from "../components/mainpage/MediumBanners";
import SectionProducts from "../components/mainpage/SectionProducts";
import WhatsAppRedirect from "../components/mainpage/WhatsAppRedirect";
import ImagesReference from "../components/mainpage/ImagesReference";
import ShowCaseCategory from "../components/mainpage/ShowCaseCategory";
import Footer from "../components/mainpage/Footer";
import HighImages from "../components/mainpage/remold/HighImages";

const MainPage = () => {

    useEffect(() => {
        document.title = `Com Todo Respeito Inc.© - Você merece ser visto e respeitado.`;
    });

    return (
        <React.Fragment>
            <HighImages />
            <SectionProducts
                title="Lançamentos"
                content="lancamentos"
                subtitle="Veja os ultimos produtos"
            />
            <SectionProducts
                title="Best Sellers of #CTR"
                content="bestsellers"
                subtitle="Os produtos que os clientes mais gostam"
            />
            <Footer />
            <WhatsAppRedirect />
        </React.Fragment>
    );
};

export default MainPage;