/**
 * Creation Date: 25/04/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import React from "react";
import Footer from "../../../components/mainpage/Footer";

const PoliticaPrivacidade = () => {
    return (
        <React.Fragment>
            <div className="politicas-Card">

                <h1>Política de Privacidade da Com Todo Respeito Inc.</h1>
                <h2>Última atualização: 10/04/2024</h2>

                <h2>1. Introdução</h2>
                <p>A Com Todo Respeito Inc., atuando como loja virtual dedicada à venda de camisetas, moletons, corta-ventos e bonés, valoriza a privacidade e a segurança dos dados pessoais de seus clientes e usuários. Esta Política de Privacidade estabelece como as informações dos usuários são coletadas, utilizadas e protegidas em nosso site. Nosso compromisso é garantir a transparência e a segurança dos dados, em conformidade com as melhores práticas de mercado e a legislação aplicável.</p>

                <h2>2. Sobre a coleta de dados</h2>
                <p>Ao interagir com o site da Com Todo Respeito Inc., poderão ser coletadas informações de duas formas:</p>

                <p>
                    <ul>
                        <li>Informações fornecidas pelo usuário: Ao realizar um cadastro, entrar em contato conosco ou se inscrever em uma newsletter, coletamos informações como nome, e-mail, número de telefone e endereço de entrega. Esses dados são necessários para a gestão de pedidos, comunicação com clientes e envio de informações sobre nossos produtos e serviços, conforme consentido pelo usuário.</li>

                        <li>Informações de navegação no site: Utilizamos tecnologias como cookies para coletar dados técnicos sobre a navegação no site, incluindo tipo de navegador, duração da visita e páginas acessadas. Essas informações ajudam a melhorar a experiência do usuário, entender padrões de navegação e aprimorar nossos serviços.</li>
                    </ul>
                </p>

                <h2>3. Uso das informações</h2>
                <p>As informações coletadas têm como objetivo:</p>

                <p>
                    <ul>
                        <li>Facilitar, agilizar e cumprir os compromissos estabelecidos entre o usuário e nossa empresa;</li>
                        <li>Melhorar a experiência dos usuários e a qualidade dos serviços oferecidos;</li>
                        <li>Realizar análises estatísticas para identificar o perfil dos usuários e desenvolver campanhas de marketing direcionadas, respeitando sempre a preferência do usuário.</li>
                    </ul>
                </p>

                <h2>4. Compartilhamento de informações</h2>
                <p>A Com Todo Respeito Inc. compromete-se a não vender ou alugar as informações pessoais dos usuários. O compartilhamento de dados pode ocorrer com:</p>

                <p>
                    <ul>
                        <li>Parceiros logísticos para a entrega dos produtos;</li>
                        <li>Plataformas de pagamento externas, responsáveis pelo processamento de transações financeiras, visto que não realizamos operações de pagamento diretamente em nosso site;</li>
                        <li>Autoridades legais, caso haja requerimento ou obrigação legal.</li>
                    </ul>
                </p>

                <h2>5. Direitos do titular dos dados</h2>
                <p>Os usuários do site têm o direito de acessar, retificar, cancelar e se opor ao tratamento de seus dados pessoais, conforme previsto na legislação aplicável. Para exercer esses direitos, os usuários podem entrar em contato conosco através dos canais de comunicação disponibilizados em nosso site.</p>

                <h2>6. Segurança da informação</h2>
                <p>Adotamos medidas de segurança apropriadas para proteger contra o acesso não autorizado, alteração, divulgação ou destruição dos dados pessoais coletados e armazenados. Nosso compromisso é manter a segurança dos dados em conformidade com os padrões de mercado.</p>

                <h2>7. Mudanças na Política de Privacidade</h2>
                <p>Esta Política de Privacidade pode ser atualizada periodicamente. Encorajamos os usuários a revisarem nossa política regularmente para se manterem informados sobre como estamos protegendo as informações pessoais coletadas. O uso contínuo do serviço constitui a aceitação das alterações e da política como um todo.</p>
                <h2>8. Contato</h2>
                <p>Para perguntas ou dúvidas sobre esta Política de Privacidade, por favor, entre em contato através do e-mail: contato.comtodorespeito@gmail.com.</p>

                <p>A Com Todo Respeito Inc. agradece por escolher nossos produtos e serviços. Estamos comprometidos em garantir sua privacidade e proteger seus dados pessoais.</p>

            </div>
            <Footer />
        </React.Fragment>
    )
}

export default PoliticaPrivacidade;