/**
 * Creation Date: 22/08/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import React, { useEffect, useState } from 'react';
import LogoHeader from '../../images/logo-header.webp';
import { defineSacolaUser, getCurrentUser, getItemById, getSacolaUser } from '../../lib/database';
import { account } from '../../lib/appwrite';
import ItemCart from './Cart/ItemCart';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../lib/firebase';

const Sacola = ({ showing, handleChange }) => {

    const Nav = useNavigate()
    const [isClosing, setIsClosing] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [sacolaUser, setSacolaUser] = useState(null);

    const [Subtotal, setSubtotal] = useState(0);
    const [Desconto, setDesconto] = useState(0);
    const [Total, setTotal] = useState(0);

    async function getAccount() {
        setLoading(true);
        try {
            const data = await getCurrentUser(auth.currentUser.uid)
            setCurrentUser(data);
            setLoading(false);
            requestSacola(data.uid);
        }
        catch (error) {

            return setLoading(false);

        }
    }

    useEffect(() => {
        getAccount()
    }, [auth.currentUser])

    async function requestSacola(uid) {
        const sacola = await getSacolaUser(uid);

        try {
            const newSacola = JSON.parse(sacola.items);
            setSacolaUser(newSacola);
            getPrices(newSacola)
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    }


    async function removepos(index) {
        try {
            const newSacola = [...sacolaUser];
            newSacola.splice(index, 1);
            setLoading(true);
            await defineSacolaUser(currentUser.uid, newSacola);

            setSacolaUser(newSacola)
            setLoading(false);
            getPrices(newSacola)
        } catch (error) {
            console.error("Erro ao remover item:", error);
            setLoading(false);
        }
    }

    async function getPrices(itens) {
        let sub = 0;
        let desc = 0;


        await Promise.all(
            itens.map(async (item) => {
                const itemDB = await getItemById(item.id);

                sub += itemDB.price;
                desc += itemDB.desconto;
            })
        );


        setSubtotal(sub);
        setDesconto(desc);
        setTotal(sub - desc);
    }

    async function goToCheckOut() {
        Nav('/checkout')
    }



    return (
        <React.Fragment>
            {
                showing ?
                    <React.Fragment>
                        <section className={!isClosing ? "sacola-items-show" : "sacola-items-show-closing"}>
                            <div className="sacola-items-header">
                                <div className="sacola-side-header">

                                </div>
                                <div className="sacola-side-header">
                                    <img src={LogoHeader} alt="Logo" />
                                </div>
                                <div className="sacola-side-header">
                                    <button onClick={() => {
                                        handleChange(!showing); setIsClosing(true); setTimeout(() => {
                                            setIsClosing(false)
                                        }, 500)
                                    }} className="sacola-side-btn"><i className="fa-solid fa-xmark"></i></button>
                                </div>
                            </div>
                            <div className='sacola-subheader-cart'>
                                <h1>Sacola de Compras</h1>
                            </div>
                            <div className='sacola-items-showing-all'>
                                {sacolaUser == "" || !currentUser ?
                                    <div className="noItemSacola">
                                        <h2>A sacola está vazia.</h2>
                                        <p>Você não adicionou nenhum item em sua sacola.</p>
                                        <button onClick={() => {
                                            handleChange(!showing); setIsClosing(true); setTimeout(() => {
                                                setIsClosing(false)
                                            }, 500)
                                        }}>PROCURAR ITENS</button>
                                    </div>
                                    :
                                    <React.Fragment>
                                        {sacolaUser && sacolaUser.map((item, index) => {
                                            return (
                                                <ItemCart
                                                    content={item}
                                                    removepos={removepos}
                                                    index={index}
                                                />
                                            )
                                        })}
                                    </React.Fragment>
                                }

                            </div>
                            {sacolaUser != "" &&
                                <div className='sacola-finish-bts'>
                                    <div className='info-buy'>
                                        <h4>Subtotal: R${Subtotal.toFixed(2)}</h4>
                                        <h4>Frete: A CALCULAR</h4>
                                        <h2>Total: R${Total.toFixed(2)}</h2>
                                    </div>
                                    <div className='btn-principal'>
                                        <button onClick={() => {
                                            handleChange(!showing); setIsClosing(true); goToCheckOut(); setTimeout(() => {
                                                setIsClosing(false);

                                            }, 500)
                                        }}>IR PARA O CHECKOUT</button>
                                    </div>
                                </div>
                            }

                        </section>
                    </React.Fragment>
                    :
                    null
            }
        </React.Fragment>
    )

}

export default Sacola;