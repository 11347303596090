/**
 * Creation Date: 22/08/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import Logo from '../images/CoroaOnly.webp';

export default function Loader() {
    return (
        <div className="LoadingWrapperComponent">
            <img src={Logo} alt="LoadingLogo" />
        </div>
    )
}