/**
 * Creation Date: 22/08/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import React, { useEffect, useState } from "react";
import CardProduct from "../productpage/CardProduct";
import { GetAllProducts } from "../../lib/database";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const SectionProducts = ({ title, content, subtitle }) => {

    const [AllProducts, setAllProducts] = useState(null);

    useEffect(() => {
        requestProducts()
    }, [])

    async function requestProducts() {
        const resp = await GetAllProducts();
        setAllProducts(resp.reverse());
    }

    if (content == "lancamentos") {
        return (
            <React.Fragment>
                <section className="SectionContent">
                    <div className="titleSection">
                        <h1>{title}</h1>
                        <p>{subtitle}</p>
                    </div>
                    <div className="ListProductsIndex">
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            slidesPerView={4}
                            pagination={{ clickable: true }}
                            navigation
                        >
                            {AllProducts && AllProducts.map((product) => {
                                return (
                                    <SwiperSlide key={product.id}>
                                        <CardProduct
                                            product={product}
                                        />
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                    <div className="ListProductsIndexMobile">
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            slidesPerView={2}
                            pagination={{ clickable: true }}
                            navigation
                        >
                            {AllProducts && AllProducts.map((product) => {
                                return (
                                    <SwiperSlide key={product.id}>
                                        <CardProduct
                                            product={product}
                                        />
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </section>
            </React.Fragment>
        )
    }

    if (content == "bestsellers") {
        return (
            <React.Fragment>
                <section className="SectionContent">
                    <div className="titleSection">
                        <h1>{title}</h1>
                        <p>{subtitle}</p>
                    </div>
                    <div className="ProductListShow">
                        <div className="ListProductsIndex">
                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                slidesPerView={4}
                                pagination={{ clickable: true }}
                                navigation
                            >
                                {AllProducts && AllProducts
                                    .filter((item) => item.Vendas != 0)
                                    .sort((a, b) => b.Vendas - a.Vendas)
                                    .map((product) => {

                                        return (
                                            <SwiperSlide key={product.id}>
                                                <CardProduct
                                                    product={product}
                                                />
                                            </SwiperSlide>
                                        )
                                    })}
                            </Swiper>
                        </div>
                        <div className="ListProductsIndexMobile">
                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                slidesPerView={2}
                                pagination={{ clickable: true }}
                                navigation
                            >
                                {AllProducts && AllProducts
                                    .filter((item) => item.Vendas != 0)
                                    .sort((a, b) => b.Vendas - a.Vendas)
                                    .map((product) => {
                                        return (
                                            <SwiperSlide key={product.id}>
                                                <CardProduct
                                                    product={product}
                                                />
                                            </SwiperSlide>
                                        )
                                    })}
                            </Swiper>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default SectionProducts;