/**
 * Creation Date: 26/04/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */


import React, { useEffect, useState } from "react";
import { account } from "../../lib/appwrite";
import { getCurrentUser } from "../../lib/database";
import Loader from "../../components/Loading";
import { auth } from "../../lib/firebase";

const AdminPage = () => {

    const [userLogged, setUserlogged] = useState([]);
    const [Loading, setLoading] = useState(false);
    const [welcomeMessage, setWelcomeMessage] = useState("");

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    const data = await getCurrentUser(user.uid);
                    setUserlogged(data);

                    if(data.label != "admin") {
                        window.location.href = window.location.origin;
                    }
                } catch (error) {
                    console.warn("Erro ao obter dados do usuário: ", error);
                } finally {
                    setLoading(false);
                }
            } else {
                console.warn("Usuário não autenticado");
                setLoading(false);
            }
        });
        return () => unsubscribe();
    }, []);

    function c() {
        window.document.title = "Com Todo Respeito - Administração";

        const data = new Date();
        const hora = data.getHours();

        if (hora > 0 && hora < 6) {
            setWelcomeMessage("Boa madrugada,")
        }
        else if (hora >= 6 && hora < 12) {
            setWelcomeMessage("Bom dia,")
        }
        else if (hora >= 12 && hora < 18) {
            setWelcomeMessage("Boa tarde,")
        }
        else {
            setWelcomeMessage("Boa noite,")
        }
    }

    useEffect(() => {
        c()
    })

    if (Loading) {
        return <Loader />
    }

    return (
        <React.Fragment>
            <div className="adminPageContent">
                <h1>{welcomeMessage} {userLogged.nome_completo}</h1>
            </div>
        </React.Fragment>
    )
}

export default AdminPage;