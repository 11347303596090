/**
 * Creation Date: 22/08/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import { Link } from "react-router-dom";
import '../style/header.css';

import React, { useEffect, useState, KeyboardEvent } from "react";
import Sacola from "./header/Sacola";
import NavigatorComponent from "./header/Navigation";
import { account } from "../lib/appwrite";
import { getCurrentUser } from "../lib/database";
import { useNavigate } from "react-router-dom";
import NavigaitonBar from "./navigation/NavigationBar";
import LogoHeader from '../images/logo-header.webp';
import { lineWobble } from 'ldrs'
import { auth } from "../lib/firebase";

lineWobble.register()

const Header = () => {

    const [scrollY, setScrollY] = useState(0);
    const [CartIsOn, setCartIsOn] = useState(false);
    const [NavIsOn, setNavIsOn] = useState(false);

    const [Pesquisa, setPesquisa] = useState("");

    const [CurrentUser, setCurrentUser] = useState(null);
    const [FirstName, setFirstName] = useState("");

    const [hoverAtual, sethoverAtual] = useState(null);

    const [isLoading, setLoading] = useState(false);

    const Nav = useNavigate();

    const urlParams = new URLSearchParams(window.location.search);

    const NavigationLinks = [
        {
            id: 0,
            name: "VESTUÁRIO",
            link: "",
            onHover: "yes",
            focus: false
        },
        {
            id: 1,
            name: "ACESSÓRIOS",
            link: "",
            onHover: "yes",
            focus: false
        },
        {
            id: 2,
            name: "MAIS VENDIDOS",
            link: "",
            onHover: "",
            focus: false
        },
        {
            id: 3,
            name: "SALE",
            link: "",
            onHover: "",
            focus: true
        },
        {
            id: 4,
            name: "CONHECER",
            link: "",
            onHover: "",
            focus: false
        }
    ]

    useEffect(() => {
        if (window.location.href.includes("search")) {
            urlParams.forEach((i) => i && setPesquisa(i.toLowerCase()))
        }
    }, [])

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function handleChange(value) {
        if (!value) {
            setTimeout(() => {
                setCartIsOn(value);
            }, 190);
        }

    }

    function handleChangeNav(value) {
        if (!value) {
            setTimeout(() => {
                setNavIsOn(value);
            }, 190);
        }
    }

    function getSearch() {
        if (Pesquisa) {
            window.location.href = "/search?=" + Pesquisa;
        }
    }

    

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    const data = await getCurrentUser(user.uid);
                    setCurrentUser(data);
                    setFirstName(data.nome_completo.split(' ')[0]);
                } catch (error) {
                    console.warn("Erro ao obter dados do usuário: ", error);
                } finally {
                    setLoading(false);
                }
            } else {
                console.warn("Usuário não autenticado");
                setLoading(false);
            }
        });
        return () => unsubscribe();
    }, []);

    if (window.location.href.includes("admin")) {
        return <NavigaitonBar
            CurrentUser={CurrentUser}
        />;
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            getSearch()
            e.preventDefault();
        }
    };

    return (
        <React.Fragment>
            <header className="HeaderComponent">
                <div className="HeaderComponent-container">
                    <div className="TopHeaderComponent">
                        <div className="RightSideComponent">
                            <Link reloadDocument to={window.location.origin}>
                                <img
                                    src={LogoHeader}
                                    alt="Logo"
                                />
                            </Link>
                            <div className="NavigationLabels">
                                {NavigationLinks.map((item) => {
                                    return (
                                        <>
                                            {item.onHover == ""
                                                ?
                                                <Link reloadDocument to={window.location.origin + item.link}>
                                                    <div className="labelNavigation">
                                                        <span id={item.focus && "focus"}>{item.name}</span>
                                                    </div>


                                                </Link>
                                                :
                                                <>
                                                    <div className="labelNavigation" onMouseEnter={() => sethoverAtual(item.name)}>
                                                        <span id={item.focus && "focus"}>{item.name}</span>
                                                    </div>
                                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.75008 5.54166L9.50008 10.2917L14.2501 5.54166L15.8334 7.12499L9.50008 13.4583L3.16675 7.12499L4.75008 5.54166Z" fill="black" />
                                                    </svg>
                                                    {item.name == "VESTUÁRIO" && hoverAtual == "VESTUÁRIO" &&

                                                        <div className="BoxNavigation" onMouseLeave={() => sethoverAtual(null)}>
                                                            <div className="BoxNavigation-container">
                                                                <div class="BoxNavigation__item">
                                                                    <Link reloadDocument to={window.location.origin + "/camisetas"}>
                                                                        <span>CAMISETAS</span>
                                                                    </Link>
                                                                </div>
                                                                <div class="BoxNavigation__item">
                                                                    <Link reloadDocument to={window.location.origin + "/moletons"}>
                                                                        <span>MOLETONS</span>
                                                                    </Link>
                                                                </div>
                                                                <div class="BoxNavigation__item">
                                                                    <Link reloadDocument to={window.location.origin + "/corta-ventos"}>
                                                                        <span>CORTA-VENTOS</span>
                                                                    </Link>
                                                                </div>
                                                                <div class="BoxNavigation__item">
                                                                    <Link reloadDocument to={window.location.origin + "/calcas"}>
                                                                        <span>CALÇAS</span>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {item.name == "ACESSÓRIOS" && hoverAtual == "ACESSÓRIOS" &&

                                                        <div className="BoxNavigation" onMouseLeave={() => sethoverAtual(null)}>
                                                            <div className="BoxNavigation-container">
                                                                <div class="BoxNavigation__item">
                                                                    <Link reloadDocument to={window.location.origin + "/bones"}>
                                                                        <span>BONÉS</span>
                                                                    </Link>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="SideSearchAccount">
                            <div className="HeaderLeftInformationComponent">
                                <div className="InputBoxComponent">
                                    <button>
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </button>
                                    <input
                                        type="text"
                                        placeholder="o que você está procurando?"
                                        value={Pesquisa}
                                        onKeyDown={handleKeyDown}
                                        onChange={(e) => setPesquisa(e.target.value)}
                                    />

                                </div>

                            </div>
                            <div className="IconsFlexContent">
                                <div className="IconWrapperContent" onClick={() => { CurrentUser ? Nav('/accounts/myaccount/favorites') : Nav('/accounts/signin') }}>
                                    <span><svg width="27" height="23" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_6_306)">
                                            <path d="M24.4012 13.2606L15.6799 21.9833C15.1017 22.5614 14.3176 22.8861 13.5 22.8861C12.6824 22.8861 11.8983 22.5614 11.3201 21.9833L2.5989 13.2621C1.87797 12.5475 1.3053 11.6975 0.91375 10.7609C0.522205 9.82434 0.319502 8.81967 0.317279 7.80456C0.315056 6.78945 0.513357 5.78389 0.900797 4.84563C1.28824 3.90736 1.85719 3.05486 2.57498 2.33706C3.29277 1.61927 4.14527 1.05032 5.08354 0.662882C6.02181 0.275442 7.02736 0.0771413 8.04247 0.0793642C9.05758 0.081587 10.0623 0.284289 10.9988 0.675835C11.9354 1.06738 12.7854 1.64006 13.5 2.36099C14.9516 0.945539 16.9024 0.158971 18.9298 0.171674C20.9572 0.184378 22.898 0.995329 24.3318 2.42886C25.7655 3.86238 26.5767 5.80305 26.5897 7.83047C26.6027 9.85789 25.8164 11.8088 24.4012 13.2606Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_6_306">
                                                <rect width="27" height="23" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    </span>
                                </div>
                                <div className="IconWrapperContent" onClick={() => { setCartIsOn(!CartIsOn) }}>
                                    <span><svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14 1.75C15.1603 1.75 16.2731 2.21094 17.0936 3.03141C17.9141 3.85188 18.375 4.96468 18.375 6.125V7H9.625V6.125C9.625 4.96468 10.0859 3.85188 10.9064 3.03141C11.7269 2.21094 12.8397 1.75 14 1.75ZM20.125 7V6.125C20.125 4.50055 19.4797 2.94263 18.331 1.79397C17.1824 0.645311 15.6245 0 14 0C12.3755 0 10.8176 0.645311 9.66897 1.79397C8.52031 2.94263 7.875 4.50055 7.875 6.125V7H1.75V24.5C1.75 25.4283 2.11875 26.3185 2.77513 26.9749C3.4315 27.6313 4.32174 28 5.25 28H22.75C23.6783 28 24.5685 27.6313 25.2249 26.9749C25.8813 26.3185 26.25 25.4283 26.25 24.5V7H20.125ZM3.5 8.75H24.5V24.5C24.5 24.9641 24.3156 25.4092 23.9874 25.7374C23.6592 26.0656 23.2141 26.25 22.75 26.25H5.25C4.78587 26.25 4.34075 26.0656 4.01256 25.7374C3.68437 25.4092 3.5 24.9641 3.5 24.5V8.75Z" fill="black" />
                                    </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="HeaderRightInformationComponent">
                                {isLoading ?
                                    <div className="ContentItemsButtons">
                                        <Link reloadDocument to={null}>
                                            <div className="AccountDetails">
                                                <div className="RightSideAccount">
                                                    <h2>Minha conta</h2>
                                                    <p>
                                                        <l-line-wobble
                                                            size="80"
                                                            stroke="5"
                                                            bg-opacity="0.1"
                                                            speed="1.75"
                                                            color="black"
                                                        ></l-line-wobble></p>
                                                </div>
                                                <div className="LeftSideAccount">

                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    :
                                    <div className="ContentItemsButtons">
                                        {!CurrentUser ?
                                            <Link reloadDocument to={window.location.origin + '/accounts/signin'}>
                                                <div className="AccountDetails">
                                                    <div className="RightSideAccount">
                                                        <h2>Minha conta</h2>
                                                        <p>Realizar o login</p>
                                                    </div>
                                                    <div className="LeftSideAccount">
                                                        <img src={'https://static.vecteezy.com/system/resources/previews/009/292/244/non_2x/default-avatar-icon-of-social-media-user-vector.jpg'} alt="Profile Picture" />
                                                    </div>
                                                </div>
                                            </Link>
                                            :
                                            <Link reloadDocument to={window.location.origin + '/accounts/myaccount'}>
                                                <div className="AccountDetails">
                                                    <div className="RightSideAccount">
                                                        <h2>Minha conta</h2>
                                                        <p>Olá, {FirstName}</p>
                                                    </div>
                                                    <div className="LeftSideAccount">
                                                        <img src={CurrentUser.photoURL} alt="Profile Picture" />
                                                    </div>
                                                </div>
                                            </Link>
                                        }

                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </header>
            <Sacola
                showing={CartIsOn}
                handleChange={handleChange}
            />
            <NavigatorComponent
                showing={NavIsOn}
                handleChange={handleChangeNav}
                currentUser={CurrentUser}
            />
            <header className="HeaderComponentMobile">
                <section className="MainHeaderCell">
                    <div className="TopContent">
                        <div className="InnerSide">
                            <button className="ButtonContent" onClick={() => { setNavIsOn(!NavIsOn) }}>
                                <span><i className="fa-solid fa-bars"></i></span>
                            </button>
                            <div></div>
                        </div>
                        <div className="InnerSide">
                            <Link reloadDocument to={window.location.origin}>
                                <img src={LogoHeader} alt="Logo CTR" />
                            </Link>
                        </div>
                        <div className="InnerSide">
                            <div className="WrapperButton">
                                {/*<>{!CurrentUser ?
                                    <button onClick={() => Nav('/accounts/register')} className="ButtonContent">
                                        <span><i className="fa-solid fa-user"></i></span>
                                    </button>
                                    :
                                    <button onClick={() => Nav('/accounts/myaccount')} className="ButtonContent">
                                        <span><i className="fa-solid fa-user"></i></span>
                                    </button>
                                }
                                </> */}
                                <button className="ButtonContent" onClick={() => Nav("/accounts/myaccount/favorites")}>
                                    <span>
                                        <svg width="20" height="20" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_78_164)">
                                                <path d="M13.8926 7.95165L9.17842 12.6666C8.86587 12.9791 8.44203 13.1546 8.00009 13.1546C7.55815 13.1546 7.1343 12.9791 6.82175 12.6666L2.10759 7.95248C1.7179 7.56619 1.40834 7.10673 1.19669 6.60048C0.985048 6.09423 0.875479 5.55116 0.874278 5.00245C0.873076 4.45374 0.980266 3.9102 1.18969 3.40303C1.39912 2.89586 1.70666 2.43505 2.09466 2.04705C2.48265 1.65905 2.94346 1.35151 3.45064 1.14209C3.95781 0.932658 4.50135 0.825469 5.05006 0.82667C5.59877 0.827872 6.14183 0.937441 6.64808 1.14909C7.15433 1.36073 7.61379 1.67029 8.00009 2.05998C8.78473 1.29487 9.83921 0.869701 10.9351 0.876568C12.031 0.883434 13.0801 1.32179 13.8551 2.09667C14.6301 2.87155 15.0686 3.92056 15.0756 5.01646C15.0826 6.11236 14.6576 7.1669 13.8926 7.95165Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_78_164">
                                                    <rect width="16" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </span>
                                </button>
                            </div>
                            <button className="ButtonContent" onClick={() => { setCartIsOn(!CartIsOn) }}>
                                <span>
                                    <svg width="20" height="20" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_78_159)">
                                            <path d="M7.5 0.875C8.1216 0.875 8.71774 1.10547 9.15728 1.5157C9.59682 1.92594 9.84375 2.48234 9.84375 3.0625V3.5H5.15625V3.0625C5.15625 2.48234 5.40318 1.92594 5.84272 1.5157C6.28226 1.10547 6.8784 0.875 7.5 0.875ZM10.7812 3.5V3.0625C10.7812 2.25027 10.4355 1.47132 9.82019 0.896985C9.20484 0.322655 8.37024 0 7.5 0C6.62976 0 5.79516 0.322655 5.17981 0.896985C4.56445 1.47132 4.21875 2.25027 4.21875 3.0625V3.5H0.9375V12.25C0.9375 12.7141 1.13504 13.1592 1.48667 13.4874C1.83831 13.8156 2.31522 14 2.8125 14H12.1875C12.6848 14 13.1617 13.8156 13.5133 13.4874C13.865 13.1592 14.0625 12.7141 14.0625 12.25V3.5H10.7812ZM1.875 4.375H13.125V12.25C13.125 12.4821 13.0262 12.7046 12.8504 12.8687C12.6746 13.0328 12.4361 13.125 12.1875 13.125H2.8125C2.56386 13.125 2.3254 13.0328 2.14959 12.8687C1.97377 12.7046 1.875 12.4821 1.875 12.25V4.375Z" fill="black" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_78_159">
                                                <rect width="15" height="14" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                            </button>
                        </div>
                    </div>

                </section>
            </header>
        </React.Fragment>
    )
}

export default Header;