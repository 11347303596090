/**
 * Creation Date: 25/04/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import React from "react";
import Footer from "../../components/mainpage/Footer";
import dep from './depclient.json'

const Depoimentos = () => {
    return (
        <React.Fragment>
            <div className="politicas-Card">
                <h1>DEPOIMENTOS DE CLIENTES</h1>
                <div className="depoimentos-Clientes">
                    {dep.map((depclient) => {
                        return (
                            <div className="depoimento">
                                {depclient.image && <img src={depclient.image} />}
                                <p>{depclient.descricao}</p>
                                <h4>{depclient.nome} - {depclient.cidade} {depclient.estado}</h4>
                                <p>{depclient.data}</p>
                            </div>)
                    })}
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default Depoimentos;