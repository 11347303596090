/**
 * Creation Date: 25/02/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import { Link, useNavigate } from "react-router-dom";

const SucessPage = () => {

    const Nav = useNavigate();

    return (
        <section className="SucessPage-Component">
            <div className="Top-SucessPage">
                <svg width="82" height="82" viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M53.75 96L25.25 67.5L32.375 60.375L53.75 81.75L99.625 35.875L106.75 43L53.75 96Z" fill="black" />
                    <circle cx="66" cy="66" r="64.5" stroke="black" stroke-width="3" />
                </svg>

                <h1>Sucesso!</h1>
                <p>Você finalizou sua compra com sucesso.</p>
                <button onClick={() => Nav('/accounts/myaccount/orders')}>VER EM MEUS PEDIDOS</button>
            </div>
            <div className="lineCrossing"></div>
            <div className="informationBottomSide">
                <p>Em-breve, você receberá uma mensagem pelo WhatsApp  para confirmação do seu pedido.</p>
            </div>

        </section>
    )
}

export default SucessPage;