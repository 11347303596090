/**
 * Creation Date: 25/02/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import React, { useEffect, useState } from "react";
import { Link, useFetcher, useParams } from "react-router-dom";
import { AdicionarItemSacola, EditarFavoritos, GetAllProducts, getCurrentUser, getFavoritesUser, getSacolaUser } from "../lib/database";
import Loader from '../components/Loading.js';
import CardProduct from '../components/productpage/CardProduct.js';
import '../style/productpage.css'
import { account } from "../lib/appwrite.js";
import Swal from "sweetalert2";
import { ring } from 'ldrs'

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import WhatsAppRedirect from "../components/mainpage/WhatsAppRedirect.js";
import Footer from "../components/mainpage/Footer.js";

import TamanhosGuia1 from '../images/TamanhosGuia1.webp';
import TamanhosGuia2 from '../images/TamanhosGuia2.webp';
import { auth } from "../lib/firebase.js";

ring.register()

const ProdutoPage = () => {
    const { id, url } = useParams();

    const [Product, setProduct] = useState({});
    const [MoreProducts, setMoreProducts] = useState([]);
    const [Photos, setPhotos] = useState(null);
    const [Loading, setLoading] = useState(false);

    const [Tamanho, setTamanho] = useState(null);
    const [Cor, setCor] = useState(null);
    const [Favorite, setFavorite] = useState(false);

    const [currentUser, setCurrentUser] = useState(null);

    const [Fixed, setFixed] = useState(false);


    const [selectedDescription, setselectedDescription] = useState("Descricao");

    async function getAccount() {
        setLoading(true);
        try {
                const data = await getCurrentUser(auth.currentUser.uid)
                setCurrentUser(data);
                setLoading(false);
            
        }
        catch (error) {

            return setLoading(false);

        }
    }

    useEffect(() => {
        getAccount()
    }, [auth.currentUser])

    function changePageName() {
        if (Product.url) {
            document.title = Product.name + ' - COM TODO RESPEITO';
        }
    }

    async function requestProduct() {
        try {
            const response = await GetAllProducts();
            const data = response.find((item) => item.url == url);

            setMoreProducts(response);
            setProduct(data);
            setPhotos(JSON.parse(data.photos));

        }
        catch (error) {
            console.error("Erro ao pegar o produto atual: ", error);
            throw new Error("Erro: ", error);
        }
    }

    useEffect(() => {
        requestProduct();
    }, [])

    useEffect(() => {
        changePageName()
    })

    useEffect(() => {
        window.addEventListener("scroll", verifyPositionFixed);
        return () => {
            window.removeEventListener("scroll", verifyPositionFixed);
        };
    }, []);
    useEffect(() => {
        async function SetFavorite() {
            if (currentUser) {
                setLoading(true);
                try {
                    const res = await getFavoritesUser(currentUser.uid);
                    const favoritos = res.array || [];

                    console.log(favoritos)
                    console.log(Product)
        
                    const produtoIsFavorited = favoritos.find(item => item.url === Product.url);


                    if (produtoIsFavorited) {
                        setFavorite(true);
                    }
                    setLoading(false);
                } catch (error) {
                    console.error("Error while setting favorite:", error);
                    setLoading(false);
                }
            }
        }
        
        
        SetFavorite()
    }, [currentUser])

    function verifyPositionFixed() {
        if (window.scrollY > document.documentElement.scrollHeight * 0.75) {
            setFixed(false);
        }
        else {
            setFixed(true);
        }
    }

    if (!Product || !Product.url) {
        return (
            <Loader />
        )
    }

    async function unFavoriteProduct() {
        setLoading(true);
        if (currentUser) {
            try {
                const response = await getFavoritesUser(currentUser.uid);
                const favoritos = JSON.parse(response.result.items) || [];


                const indexToRemove = favoritos.findIndex(item => item == Product.url);

                if (indexToRemove !== -1) {
                    favoritos.splice(indexToRemove, 1);
                }

                await EditarFavoritos(currentUser.uid, favoritos);
                setLoading(false);
                setFavorite(false)
            }
            catch (error) {
                setLoading(false);
                setFavorite(true)
                console.log("Erro ao favoritar produto: ", error);
            }

        }
        else {
            Swal.fire({
                title: "ERRO!",
                text: "Entre em uma conta para favoritar.",
            });
            setLoading(false);
        }
    }

    async function favoriteProduct() {
        setLoading(true);
        if (currentUser) {
            try {
                const response = await getFavoritesUser(currentUser.uid);
                
                const favoritos = JSON.parse(response.result.items) || [];

                console.log(favoritos)

                favoritos.push(Product.url);

                await EditarFavoritos(currentUser.uid, favoritos);
                setLoading(false);
                setFavorite(true)
            }
            catch (error) {
                setLoading(false);
                setFavorite(false);
                console.warn("Erro ao favoritar produto: ", error)
            }
        }
        else {
            Swal.fire({
                title: "ERRO!",
                text: "Entre em uma conta para favoritar.",
            });
            setLoading(false);
        }
    }

    async function addToCart() {
        setLoading(true);
        if (currentUser) {
            if (Tamanho && Cor) {
                try {
                    await AdicionarItemSacola(
                        currentUser.uid,
                        Product.id,
                        Tamanho,
                        Cor
                    )
                        .then(() => {
                            setLoading(false);
                            window.location.reload()
                        })
                }
                catch (error) {
                    console.log("Erro ao adicionar a sacola");
                    setLoading(false);
                }
            }
            else {
                Swal.fire({
                    title: "ERRO!",
                    text: "Por favor, selecione a Cor e o Tamanho.",
                });
                setLoading(false);
            }
        }
        else {
            window.location.href = window.location.origin + "/accounts/register"
        }

    }
    return (
        <>

            <section className="Product-Wrapper-Page-Show">
                <div className="Product-Photos-Show">
                    <div className="Product-Caminho-URL">
                        <span><Link reloadDocument to={window.location.origin}><i className="fa-solid fa-chevron-left"></i> COM-TODO-RESPEITO</Link> / <Link reloadDocument to={window.location.origin + "/" + (Product.type).toLowerCase()}>{(Product.type).toUpperCase()}</Link> / {(Product.name).toUpperCase()} </span>
                    </div>
                    {
                        Photos && Array.isArray(Photos) && Photos.length > 0 ? (
                            <>
                                {Photos.map((foto, index) => (
                                    <img
                                        key={index}
                                        className={index === 0 ? "pictureMain" : "pictureSecondary"}
                                        src={foto}
                                        alt="Product Pictures"
                                    />
                                ))}
                            </>
                        ) : (
                            <img className="defaultImage" src={Photos} alt="Default" />
                        )
                    }
                    <section className="descriptionProduct">
                        <div className="descriptionTop">
                            <button id={selectedDescription == "Descricao" && "selected"} onClick={() => { setselectedDescription("Descricao") }}>DESCRIÇÃO</button>
                            <button id={selectedDescription == "Caracteristicas" && "selected"} onClick={() => { setselectedDescription("Caracteristicas") }}>CARACTERISTICAS</button>
                            <button id={selectedDescription == "Avaliacoes" && "selected"} onClick={() => { setselectedDescription("Avaliacoes") }}>AVALIAÇÕES</button>
                            <button id={selectedDescription == "Garantias" && "selected"} onClick={() => { setselectedDescription("Garantias") }}>GARANTIAS E TROCAS</button>
                        </div>
                        <div className="descriptionContent">
                            {selectedDescription == "Descricao" &&
                                <div className="InsideDescription">
                                    <p>Encontre a fusão perfeita entre estilo e conforto com {Product.name}. Feita com uma mistura exclusiva de tecidos de alta qualidade, esta camiseta oferece uma sensação de maciez excepcional, enquanto sua modelagem genuinamente streetwear garante um visual autêntico. Com um ajuste cuidadosamente pensado, esta camiseta é ideal para complementar seus looks urbanos e estabelecer sua presença com confiança.</p>
                                    <h2>INFORMAÇÕES: </h2>
                                    <ul>
                                        <li>Material: Tecido 100% Algodão, Fio 30.1 penteado. (Considerado umas das Melhores Malhas do Brasil - Alta Qualidade de Tecido.)</li>
                                    </ul>
                                    <div className="imageWrapperDesc">
                                        <img src={TamanhosGuia1} alt="Tamanhos" />
                                        <img src={TamanhosGuia2} alt="Tamanhos" />
                                    </div>
                                    <h2>IMPORTANTE SABER:</h2>
                                    <ul>
                                        <li>As cores poderão variar de acordo com o monitor ou dispositivo utilizado</li>
                                        <li>As medidas das peças podem sofrer variações mínimas nos tamanhos</li>
                                        <li>Encolhimento em produtos de algodão podem ocorrer entre 3% a 5% </li>
                                    </ul>
                                    <h2>LAVAGEM E CONSERVAÇÃO:</h2>
                                    <ul>
                                        <li>Lavar com água quente até 40º</li>
                                        <li>Não usar alvejante à base de cloro</li>
                                        <li>Não utilizar secadora</li>
                                        <li>Secar na vertical sem torcer</li>
                                        <li>Usar ferro até 110º e não passar sob a estampa</li>
                                        <li>Lavagem a seco (Usar hidrocarboneto ou percloroetileno)</li>
                                    </ul>
                                    <img src={"https://images.tcdn.com.br/img/editor/up/747002/icones_lavagem_hoshwear.png"} alt="Lavagem_icones" />

                                </div>
                            }
                            {selectedDescription == "Caracteristicas" &&
                                <div className="InsideDescription">
                                    <ul>
                                        <li>Cor: {JSON.parse(Product.color)}</li>
                                        <li>Gênero: {Product.gener}</li>
                                        <li>Idade: Jovens/Adultos</li>
                                    </ul>
                                </div>
                            }
                            {selectedDescription == "Garantias" &&
                                <div className="InsideDescription">
                                    <h2>GARANTIA</h2>
                                    <p>Todos nossos produtos possuem garantia de 3 meses contra defeitos de fabricação. Caso você tenha algum problema ao receber nossa mercadoria, entre em contato conosco pelo e-mail ou whatsapp solicitando maiores informações.</p>
                                    <h2>TROCAS</h2>
                                    <p>Todos nossos produtos possuem a primeira troca grátis em até 7 dias do recebimento, desde que respeitadas a política de trocas da marca.</p>
                                </div>
                            }
                        </div>
                    </section>
                    <section className="MoreProducts-Show-Bottom pc-bottom">
                        <h1>DEIXE SEU ESTILO TOP</h1>
                        <p>Veja mais produtos similares para você</p>
                        <div className="Products-Show-Bottom-Pg">
                            <div className="items-show-top-side">
                                <Swiper
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    slidesPerView={4}
                                    pagination={{ clickable: true }}
                                    navigation
                                >
                                    {MoreProducts.reverse().map((product) => {

                                        if (product.id == Product.id) {
                                            return null
                                        }

                                        if (product.type == Product.type) {
                                            return (
                                                <SwiperSlide key={product.id}>
                                                    <CardProduct
                                                        reload='yes'
                                                        product={product}
                                                    />
                                                </SwiperSlide>
                                            )
                                        }


                                    })}
                                </Swiper>

                            </div>

                        </div>
                    </section>
                </div>
                <div className="Product-Details-RightSide">
                    <div className={Fixed ? "Product-Inside-Details-Fixed" : "Product-Inside-Details"}>
                        <h5>COM TODO RESPEITO * {Product.gener}</h5>
                        <div className="Product-Name-Show">
                            <h2>{Product.name}</h2>
                        </div>
                        <div className="Product-Price-Show">
                            {Product.desconto > 0 ?
                                <>
                                    <p><s className="desconto">R${(Product.price).toFixed(2)}</s> <span className="priceatual">R${(Product.price - Product.desconto).toFixed(2)}</span> ou </p>
                                    <p>R${((Product.price - Product.desconto) * 0.95).toFixed(2)} <span className="greenoff">%5 off pagando pelo Pix.</span></p>
                                </>
                                :
                                <>
                                    <p>R${(Product.price - Product.desconto).toFixed(2)} ou </p>
                                    <p>R${((Product.price - Product.desconto) * 0.95).toFixed(2)} <span className="greenoff">%5 off no Pix.</span></p>
                                </>
                            }
                        </div>
                        <div className="Product-Sizes-Show">
                            <h3>Cores:</h3>
                            <div className="Product-Sizes-Content">
                                {JSON.parse(Product.color).length < 1 ?
                                    <div className="Button-Wrapper-Product-Size">
                                        <button onClick={() => { setCor(JSON.parse(Product.color)) }} className={Cor == JSON.parse(Product.color) ? "Product-Size-Btn-Wrapper Product-Size-Btn-Wrapper-Selected" : "Product-Size-Btn-Wrapper"}><span>{JSON.parse(Product.color)}</span></button>
                                    </div>
                                    :
                                    <>{JSON.parse(Product.color).map((s, i) => {
                                        return (
                                            <div key={i} className="Button-Wrapper-Product-Size">
                                                <button onClick={() => { setCor(s) }} className={Cor == s ? "Product-Size-Btn-Wrapper Product-Size-Btn-Wrapper-Selected" : "Product-Size-Btn-Wrapper"}><span>{s}</span></button>
                                            </div>
                                        )
                                    })}</>
                                }
                            </div>
                        </div>
                        <div className="Product-Sizes-Show">
                            <h3>Tamanho:</h3>
                            <div className="Product-Sizes-Content">
                                {JSON.parse(Product.sizes).length < 1 ?
                                    <div className="Button-Wrapper-Product-Size">
                                        <button onClick={() => { setTamanho(JSON.parse(Product.sizes)) }} className={Tamanho == JSON.parse(Product.sizes) ? "Product-Size-Btn-Wrapper Product-Size-Btn-Wrapper-Selected" : "Product-Size-Btn-Wrapper"}><span>{JSON.parse(Product.sizes)}</span></button>
                                    </div>
                                    :
                                    <>{JSON.parse(Product.sizes).map((s, i) => {
                                        return (
                                            <div key={i} className="Button-Wrapper-Product-Size">
                                                <button onClick={() => { setTamanho(s) }} className={Tamanho == s ? "Product-Size-Btn-Wrapper Product-Size-Btn-Wrapper-Selected" : "Product-Size-Btn-Wrapper"}><span>{s}</span></button>
                                            </div>
                                        )
                                    })}</>
                                }
                            </div>
                        </div>
                        {Loading ?
                            <div className="Product-Buttons-Show">
                                <div className="Button-Wrapper-Add-Sacola">
                                    <button className="ButtonInnerWrapperProduct">
                                        <span>
                                            <l-ring
                                                size="16"
                                                stroke="3"
                                                bg-opacity="0"
                                                speed="2"
                                                color="white"
                                            ></l-ring></span>
                                    </button>
                                </div>
                                {Favorite ?
                                    <div className="Button-Wrapper-Favorited">
                                        <button
                                            className="ButtonInnerWrapperProduct"
                                            onClick={unFavoriteProduct}
                                        >
                                            <span><i className="fa-solid fa-heart"></i></span>
                                        </button>
                                    </div>
                                    :
                                    <div className="Button-Wrapper-Favorite">
                                        <button
                                            className="ButtonInnerWrapperProduct"
                                            onClick={favoriteProduct}
                                        >
                                            <span><i className="fa-regular fa-heart"></i></span>
                                        </button>
                                    </div>
                                }
                            </div>
                            :
                            <React.Fragment>
                                {Product.disponibilidade ?
                                    <div className="Product-Buttons-Show">
                                        <div className="Button-Wrapper-Add-Sacola">
                                            <button onClick={addToCart} className="ButtonInnerWrapperProduct">
                                                <span>ADICIONAR A SACOLA</span>
                                            </button>
                                        </div>
                                        {Favorite ?
                                            <div className="Button-Wrapper-Favorited">
                                                <button
                                                    className="ButtonInnerWrapperProduct"
                                                    onClick={unFavoriteProduct}
                                                >
                                                    <span><i className="fa-solid fa-heart"></i></span>
                                                </button>
                                            </div>
                                            :
                                            <div className="Button-Wrapper-Favorite">
                                                <button
                                                    className="ButtonInnerWrapperProduct"
                                                    onClick={favoriteProduct}
                                                >
                                                    <span><i className="fa-regular fa-heart"></i></span>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div className="Product-Buttons-Show">
                                        <div className="Button-Wrapper-Add-Sacola">
                                            <button className="ButtonInnerWrapperProduct">
                                                <span>INDISPONÍVEL</span>
                                            </button>
                                        </div>
                                    </div>}

                            </React.Fragment>
                        }

                        <div className="BoxInnerProduct share">
                            <p>Compartilhe: <Link reloadDocument to={"https://wa.me/?text=" + Product.name + "%0D" + window.location.href} target="_blank"><i className="fa-brands fa-square-whatsapp"></i></Link></p>
                        </div>

                        <div className="BoxInnerProduct devolution">
                            <p>DEVOLUÇÕES GRATUITAS. NÃO SERVIU? DEVOLVA EM UM PRAZO DE 7 DIAS.</p>
                        </div>
                    </div>
                    <section className="descriptionProductMobile">
                        <div className="descriptionTop">
                            <button id={selectedDescription == "Descricao" && "selected"} onClick={() => { setselectedDescription("Descricao") }}>DESCRIÇÃO</button>
                            <button id={selectedDescription == "Caracteristicas" && "selected"} onClick={() => { setselectedDescription("Caracteristicas") }}>CARACTERISTICAS</button>
                            <button id={selectedDescription == "Avaliacoes" && "selected"} onClick={() => { setselectedDescription("Avaliacoes") }}>AVALIAÇÕES</button>
                            <button id={selectedDescription == "Garantias" && "selected"} onClick={() => { setselectedDescription("Garantias") }}>GARANTIAS E TROCAS</button>
                        </div>
                        <div className="descriptionContent">
                            {selectedDescription == "Descricao" &&
                                <div className="InsideDescription">
                                    <p>Encontre a fusão perfeita entre estilo e conforto com {Product.name}. Feita com uma mistura exclusiva de tecidos de alta qualidade, esta camiseta oferece uma sensação de maciez excepcional, enquanto sua modelagem genuinamente streetwear garante um visual autêntico. Com um ajuste cuidadosamente pensado, esta camiseta é ideal para complementar seus looks urbanos e estabelecer sua presença com confiança.</p>
                                    <h2>INFORMAÇÕES: </h2>
                                    <ul>
                                        <li>Material: Tecido 100% Algodão, Fio 30.1 penteado. (Considerado umas das Melhores Malhas do Brasil - Alta Qualidade de Tecido.)</li>
                                    </ul>
                                    <div className="imageWrapperDesc">
                                        <img src={TamanhosGuia1} alt="Tamanhos" />
                                        <img src={TamanhosGuia2} alt="Tamanhos" />
                                    </div>
                                    <h2>IMPORTANTE SABER:</h2>
                                    <ul>
                                        <li>As cores poderão variar de acordo com o monitor ou dispositivo utilizado</li>
                                        <li>As medidas das peças podem sofrer variações mínimas nos tamanhos</li>
                                        <li>Encolhimento em produtos de algodão podem ocorrer entre 3% a 5% </li>
                                    </ul>
                                    <h2>LAVAGEM E CONSERVAÇÃO:</h2>
                                    <ul>
                                        <li>Lavar com água quente até 40º</li>
                                        <li>Não usar alvejante à base de cloro</li>
                                        <li>Não utilizar secadora</li>
                                        <li>Secar na vertical sem torcer</li>
                                        <li>Usar ferro até 110º e não passar sob a estampa</li>
                                        <li>Lavagem a seco (Usar hidrocarboneto ou percloroetileno)</li>
                                    </ul>
                                    <img src={"https://images.tcdn.com.br/img/editor/up/747002/icones_lavagem_hoshwear.png"} alt="Lavagem icones" />

                                </div>
                            }
                            {selectedDescription == "Caracteristicas" &&
                                <div className="InsideDescription">
                                    <ul>
                                        <li>Cor: {JSON.parse(Product.color)}</li>
                                        <li>Gênero: {Product.gener}</li>
                                        <li>Idade: Jovens/Adultos</li>
                                    </ul>
                                </div>
                            }
                            {selectedDescription == "Garantias" &&
                                <div className="InsideDescription">
                                    <h2>GARANTIA</h2>
                                    <p>Todos nossos produtos possuem garantia de 3 meses contra defeitos de fabricação. Caso você tenha algum problema ao receber nossa mercadoria, entre em contato conosco pelo e-mail ou whatsapp solicitando maiores informações.</p>
                                    <h2>TROCAS</h2>
                                    <p>Todos nossos produtos possuem a primeira troca grátis em até 7 dias do recebimento, desde que respeitadas a política de trocas da marca.</p>
                                </div>
                            }
                        </div>
                    </section>
                    <section className="MoreProducts-Show-Bottom mobile-bottom">
                        <h1>DEIXE SEU ESTILO TOP</h1>
                        <p>Veja mais produtos similares para você!</p>
                        <div className="Products-Show-Bottom-Pg">
                            <div className="items-show-bottom">
                                <Swiper
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    slidesPerView={2}
                                    pagination={{ clickable: true }}
                                    navigation
                                >
                                    {MoreProducts.reverse().map((product) => {

                                        if (product.id == Product.id) {
                                            return null
                                        }

                                        if (product.type == Product.type) {
                                            return (
                                                <SwiperSlide key={product.id}>
                                                    <CardProduct
                                                        reload='yes'
                                                        product={product}
                                                    />
                                                </SwiperSlide>
                                            )
                                        }


                                    })}
                                </Swiper>
                            </div>

                        </div>
                    </section>
                </div>
            </section>
            <Footer />
            <WhatsAppRedirect />
        </>

    )
}
export default ProdutoPage;