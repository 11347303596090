/**
 * Creation Date: 22/08/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import { Link } from "react-router-dom";
import { logoutUser } from "../../lib/appwrite";

export default function Navigation() {
    return (
        <nav className="MyAccount-Navigation-inner">
            <ul className="MyAccount-list-Navigation">
                <li className="MyAccount--item">
                    <Link reloadDocument to={window.location.origin + "/accounts/myaccount"}>
                        <span>Dados pessoais</span>
                    </Link>
                </li>
                <li className="MyAccount--item">
                    <Link reloadDocument to={window.location.origin + "/accounts/myaccount/orders"}>
                        <span>Meus Pedidos</span>
                    </Link>
                </li>
                <li className="MyAccount--item">
                    <Link reloadDocument to={window.location.origin + "/accounts/myaccount/address"}>
                        <span>Meus Endereços</span>
                    </Link>
                </li>
                <li className="MyAccount--item">
                    <Link reloadDocument to={window.location.origin + "/accounts/myaccount/favorites"}>
                        <span>Favoritos</span>
                    </Link>
                </li>
                <li className="MyAccount--item">
                    <Link reloadDocument to={window.location.origin + "/accounts/myaccount/help"}>
                        <span>Ajuda</span>
                    </Link>
                </li>
                <li className="MyAccount--item">
                    <Link reloadDocument to={null} onClick={logoutUser}>
                        <span>Finalizar sessão</span>
                    </Link>
                </li>
            </ul>
        </nav>
    )
}