/**
 * Creation Date: 17/02/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

const endpoint = process.env.REACT_APP_API_ENDPOINT;
//const endpoint = process.env.REACT_APP_API_ENDPOINT_TEST;
const secretKey = process.env.REACT_APP_API_SECRET_KEY;

const GetAllProducts = async () => {
    try {
        const response = await fetch(`${endpoint}/api/v1/${secretKey}/products`);
        const data = await response.json();
        return data;
    }

    catch (error) {
        console.log("Erro ao pegar todos os produtos: ", error);
        throw new Error('Não foi possível pegar todos os produtos')
    }
}

const getAllUsers = async () => {
    try {
        const response = await fetch(`${endpoint}/api/v1/${secretKey}/users`);
        const data = await response.json();
        return data;
    }

    catch (error) {
        console.log("Erro ao pegar todos os produtos: ", error);
        throw new Error('Não foi possível pegar todos os produtos')
    }
}

const getCurrentUser = async (uid) => {
    try {
        const response = await fetch(`${endpoint}/api/v1/${secretKey}/users`);
        const data = await response.json();

        const currentUser = data.find((user) => user.uid === uid);
        return currentUser;
    } catch (error) {
        console.log("Erro ao pegar os usuários: ", error);
        throw new Error('Não foi possível pegar os usuários');
    }
}

const addNewEmailNewsletter = async (email) => {
    try {
        await fetch(`${endpoint}/api/v1/${secretKey}/newsletter/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email
            }),
        })
    }
    catch(error) {
        console.log("Erro ao adicionar o email para NewsLetter");
        throw new Error("Não foi possível adicionar novo email na NewsLetter");
    }
}

const getOrdersOfUser = async (uid) => {
    try {
        const response = await fetch(`${endpoint}/api/v1/${secretKey}/pedidos`);
        const data = await response.json();
        const usersOrders = data.filter((order) => order.uidComprador == uid);
        return usersOrders;
    }
    catch(error) {
        throw new Error("Não foi possível pegar todos os pedidos desse usuário.")
    }
}

const getSacolaUser = async (id) => {
    try {
        const response = await fetch(`${endpoint}/api/v1/${secretKey}/sacolas`);
        const data = await response.json();
        const current = data.find((sacola) => sacola.userRef === id);

        return current;

    }
    catch (error) {
        console.log("Erro ao pegar sacola user: ", error);
        throw new Error('Não foi possível pegar sacola user');
    }
}

const getAddressUsers = async (uid) => {
    try {
        const response = await fetch(`${endpoint}/api/v1/${secretKey}/adress`);
        const data = await response.json();

        const result = data.find((add) => add.userRef == uid);

        return result;

    }
    catch (error) {
        console.log("Erro ao pegar sacola user: ", error);
        throw new Error('Não foi possível pegar sacola user');
    }
}


const getItemById = async (id) => {
    try {
        const response = await fetch(`${endpoint}/api/v1/${secretKey}/products`);
        const data = await response.json();
        const current = data.find((items) => items.id === id);
        return current;
    }
    catch (error) {
        console.log("Erro ao pegar sacola user: ", error);
        throw new Error('Não foi possível pegar sacola user')
    }
}

const getItemByURL = async (url) => {
    try {
        const response = await fetch(`${endpoint}/api/v1/${secretKey}/products`);
        const data = await response.json();
        const current = data.find((items) => items.url === url);
        return current;
    }
    catch (error) {
        console.log("Erro ao pegar sacola user: ", error);
        throw new Error('Não foi possível pegar sacola user')
    }
}

const LimparSacola = async (uid) => {
    try {
        await fetch(`${endpoint}/api/v1/${secretKey}/sacolas/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: uid,
                items: '[]'
            }),
        })

    }
    catch (error) {
        console.log("Erro ao adicionar sacola: ", error);
        throw new Error('Não foi adicionar sacola')
    }
}

const getFavoritesUser = async (uid) => {
    try {
        const response = await fetch(`${endpoint}/api/v1/${secretKey}/favorites`);
        const data = await response.json();

        const result = data.find((add) => add.byId == uid);

        const promises = JSON.parse(result.items).map(async (res) => {
            const produto = await getItemByURL(res);
            return produto;
        });

        const array = await Promise.all(promises);

        return {array, result};

    }
    catch(error) {
        console.log("Erro ao pegar favoritos do usuario: ", error);;
        throw new Error ("Não foi possível pegar os favoritos do usuário atual.");
    }
}


const EditarFavoritos = async (uid, items) => {
    try {
        await fetch(`${endpoint}/api/v1/${secretKey}/favorites/edit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                byId: uid,
                items: JSON.stringify(items)
            }),
        })

    }
    catch (error) {
        console.log("Erro ao editar favoritos: ", error);
        throw new Error('Não foi possivel editar favoritos')
    }
}

const AdicionarItemSacola = async (uid, item, tamanho, cor) => {
    try {

        const sacolaUser = await getSacolaUser(uid) || "[]";

        const sacolaJSON = JSON.parse(sacolaUser.items || "[]");

        const itemToPush = {
            size: tamanho,
            id: item,
            cor: cor
        }

        sacolaJSON.push(itemToPush);

        await fetch(`${endpoint}/api/v1/${secretKey}/sacolas/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: uid,
                items: JSON.stringify(sacolaJSON)
            }),
        })

    }
    catch (error) {
        console.log("Erro ao adicionar sacola: ", error);
        throw new Error('Não foi adicionar sacola')
    }
}

const defineSacolaUser = async (uid, sacola) => {
    try {
        await fetch(`${endpoint}/api/v1/${secretKey}/sacolas/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: uid,
                items: JSON.stringify(sacola)
            }),
        })
    }
    catch (error) {
        console.log("Erro ao adicionar sacola: ", error);
        throw new Error('Não foi adicionar sacola')
    }
}

const createOrder = async (uid, comprador, items, totalprice, adress, payment) => {
    try {
        await fetch(`${endpoint}/api/v1/${secretKey}/pedidos/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                uidComprador: uid,
                comprador: JSON.stringify(comprador),
                items: JSON.stringify(items),
                totalprice: totalprice,
                adress: JSON.stringify(adress),
                payment: payment
            }),
        })
    }
    catch (error) {
        console.log("Erro ao adicionar sacola: ", error);
        throw new Error('Não foi adicionar sacola')
    }
}


export {
    GetAllProducts,
    getOrdersOfUser,
    getAllUsers,
    createOrder,
    getCurrentUser,
    getAddressUsers,
    defineSacolaUser,
    getItemById,
    getSacolaUser,
    AdicionarItemSacola,
    addNewEmailNewsletter,
    LimparSacola,
    getFavoritesUser,
    EditarFavoritos,
    getItemByURL
    
}