/**
 * Creation Date: 22/08/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LogoHeader from '../../images/logo-header.webp';
import { getCurrentUser } from "../../lib/database";

const NavigatorComponent = ({ showing, handleChange, currentUser }) => {

    const [isClosing, setIsClosing] = useState(false);
    const [Pesquisa, setPesquisa] = useState("");
    const urlParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        if (window.location.href.includes("search")) {
            urlParams.forEach((i) => i && setPesquisa(i.toLowerCase()))
        }
    }, [])

    function Animation() {
        handleChange(!showing);
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false)
        }, 500)
    }

    function getSearch() {
        if (Pesquisa) {
            window.location.href = "/search?=" + Pesquisa;
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            getSearch()
            e.preventDefault();
        }
    };

    return (
        <React.Fragment>
            {showing ?
                <nav className={!isClosing ? "Navigator-Command-Mobile" : "Navigator-Command-Mobile-closing"}>
                    <div className="TopNavigationComponent">
                        <button onClick={Animation} className="sacola-side-btn"><i className="fa-solid fa-xmark"></i></button>
                        <img src={LogoHeader} alt="Logo CTR" />
                        <div></div>
                    </div>
                    <div className="AccountNavigationOptiomn">
                        {!currentUser ?
                            <Link reloadDocument to={window.location.origin + "/accounts/signin"}>
                                <div className="BoxAccountShow">
                                    <img src={"https://static.vecteezy.com/system/resources/previews/009/292/244/non_2x/default-avatar-icon-of-social-media-user-vector.jpg"} alt="Profile Image" />
                                    <div className="informationAccount">
                                        <h2>Entre agora em sua conta</h2>
                                        <p>Realize o cadastro ou o login para acessar sua conta.</p>
                                    </div>
                                </div>
                            </Link>
                            :
                            <Link reloadDocument to={window.location.origin + "/accounts/myaccount"}>
                            <div className="BoxAccountShow">
                                <img src={currentUser.photoURL} alt="Profile Image" />
                                <div className="informationAccount">
                                    <h2>Minha conta</h2>
                                    <p>Olá, {currentUser.nome_completo}</p>
                                    <div className="optionAccount">
                                        <div className="optionBoxAccount">
                                            <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.25 19.6875H26.7188V22.5H11.25V19.6875ZM11.25 26.7188H29.5312V29.5312H11.25V26.7188Z" fill="black" />
                                                <path d="M39.375 5.625H5.625C4.87908 5.625 4.16371 5.92132 3.63626 6.44876C3.10882 6.97621 2.8125 7.69158 2.8125 8.4375V36.5625C2.8125 37.3084 3.10882 38.0238 3.63626 38.5512C4.16371 39.0787 4.87908 39.375 5.625 39.375H39.375C40.1209 39.375 40.8363 39.0787 41.3637 38.5512C41.8912 38.0238 42.1875 37.3084 42.1875 36.5625V8.4375C42.1875 7.69158 41.8912 6.97621 41.3637 6.44876C40.8363 5.92132 40.1209 5.625 39.375 5.625ZM39.375 8.4375V11.25H5.625V8.4375H39.375ZM5.625 36.5625V14.0625H39.375V36.5625H5.625Z" fill="black" />
                                            </svg>

                                            <p>Dados</p>
                                        </div>
                                        <div className="optionBoxAccount">
                                            <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M42.875 15.3127L34.7084 19.396M34.7084 19.396L33.6875 19.9064L24.5 24.5002M34.7084 19.396V26.5418M34.7084 19.396L15.3125 9.18766M24.5 24.5002L6.12504 15.3127M24.5 24.5002V43.896M31.8051 6.90508L35.8885 9.04883C40.2801 11.3539 42.4769 12.5054 43.6978 14.5777C44.9167 16.6479 44.9167 19.2265 44.9167 24.3817V24.6206C44.9167 29.7738 44.9167 32.3524 43.6978 34.4227C42.4769 36.495 40.2801 37.6485 35.8885 39.9535L31.8051 42.0952C28.22 43.9756 26.4274 44.9168 24.5 44.9168C22.5727 44.9168 20.7801 43.9777 17.195 42.0952L13.1116 39.9515C8.72 37.6465 6.52317 36.495 5.30225 34.4227C4.08337 32.3524 4.08337 29.7738 4.08337 24.6227V24.3838C4.08337 19.2286 4.08337 16.65 5.30225 14.5797C6.52317 12.5074 8.72 11.3539 13.1116 9.05087L17.195 6.90712C20.7801 5.0247 22.5727 4.0835 24.5 4.0835C26.4274 4.0835 28.22 5.02266 31.8051 6.90508Z" stroke="black" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                            <p>Pedidos</p>
                                        </div>
                                        <div className="optionBoxAccount">
                                            <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M45.2936 31.2125L31.8582 44.6502C30.9675 45.5407 29.7595 46.0409 28.5 46.0409C27.2405 46.0409 26.0325 45.5407 25.1417 44.6502L11.7064 31.2148C10.5957 30.1139 9.71351 28.8044 9.11032 27.3616C8.50713 25.9188 8.19486 24.3711 8.19143 22.8073C8.18801 21.2434 8.4935 19.6943 9.09037 18.2489C9.68724 16.8035 10.5637 15.4901 11.6695 14.3844C12.7753 13.2786 14.0886 12.4021 15.5341 11.8052C16.9795 11.2083 18.5286 10.9029 20.0924 10.9063C21.6562 10.9097 23.204 11.222 24.6468 11.8252C26.0896 12.4284 27.3991 13.3106 28.5 14.4212C30.7362 12.2407 33.7415 11.0289 36.8648 11.0485C39.9881 11.0681 42.978 12.3174 45.1867 14.5258C47.3954 16.7342 48.6451 19.7239 48.6652 22.8472C48.6852 25.9705 47.4739 28.9759 45.2936 31.2125Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            <p>Favoritos</p>
                                        </div>
                                        <div className="optionBoxAccount">
                                            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.5 17.9373C17.5 16.8436 17.6575 14.3279 21.1684 11.4339C23.2531 9.7156 25.7567 9.21794 28 9.18732C30.0486 9.16216 31.8795 9.50888 32.9744 10.0426C34.8458 10.9592 38.5 13.1904 38.5 17.9373C38.5 22.9325 35.3084 25.1965 31.6805 27.6914C28.0525 30.1862 27.125 31.7579 27.125 34.5623" stroke="black" stroke-width="1.5625" stroke-miterlimit="10" stroke-linecap="square" />
                                                <path d="M29.8047 40.25H24.4453C24.2339 40.25 24.0625 40.4214 24.0625 40.6328V45.9922C24.0625 46.2036 24.2339 46.375 24.4453 46.375H29.8047C30.0161 46.375 30.1875 46.2036 30.1875 45.9922V40.6328C30.1875 40.4214 30.0161 40.25 29.8047 40.25Z" fill="black" />
                                            </svg>

                                            <p>Ajuda</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </Link>
                        }
                    </div>
                    <div className="SearchNavigation">
                        <div className="SearchBox__wrapper">
                            <button onClick={getSearch}>
                                <svg width="25" height="25" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M46.55 49.875L31.5875 34.9125C30.4 35.8625 29.0344 36.6146 27.4906 37.1687C25.9469 37.7229 24.3042 38 22.5625 38C18.2479 38 14.5968 36.5053 11.609 33.516C8.62125 30.5267 7.12658 26.8755 7.125 22.5625C7.12342 18.2495 8.61808 14.5983 11.609 11.609C14.5999 8.61967 18.2511 7.125 22.5625 7.125C26.8739 7.125 30.5259 8.61967 33.5184 11.609C36.5109 14.5983 38.0048 18.2495 38 22.5625C38 24.3042 37.7229 25.9469 37.1688 27.4906C36.6146 29.0344 35.8625 30.4 34.9125 31.5875L49.875 46.55L46.55 49.875ZM22.5625 33.25C25.5313 33.25 28.0551 32.2113 30.134 30.134C32.2129 28.0567 33.2516 25.5328 33.25 22.5625C33.2484 19.5922 32.2098 17.0691 30.134 14.9934C28.0583 12.9176 25.5344 11.8782 22.5625 11.875C19.5906 11.8718 17.0675 12.9113 14.9934 14.9934C12.9192 17.0755 11.8798 19.5985 11.875 22.5625C11.8703 25.5265 12.9097 28.0503 14.9934 30.134C17.077 32.2177 19.6001 33.2563 22.5625 33.25Z" fill="#727272" />
                                </svg>
                            </button>

                            <input
                                value={Pesquisa}
                                onChange={(e) => setPesquisa(e.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="o que você está procurando?"
                            />


                        </div>
                    </div>
                    <div className="CenterNavigationComponent">
                        <div className="ListOfRedirects">
                            <Link reloadDocument to={window.location.origin + "/novidades"} onClick={Animation}>
                                <p>Novidades</p>
                                <i className="fa-solid fa-chevron-right"></i>
                            </Link>
                        </div>
                        <div className="ListOfRedirects">
                            <Link reloadDocument to={window.location.origin + "/camisetas"} onClick={Animation}>
                                <p>Camisetas</p>
                                <i className="fa-solid fa-chevron-right"></i>
                            </Link>
                        </div>
                        <div className="ListOfRedirects">
                            <Link reloadDocument to={window.location.origin + "/moletons"} onClick={Animation}>
                                <p>Moletons</p>
                                <i className="fa-solid fa-chevron-right"></i>
                            </Link>
                        </div>
                        <div className="ListOfRedirects">
                            <Link reloadDocument to={window.location.origin + "/corta-ventos"} onClick={Animation}>
                                <p>Corta-Ventos</p>
                                <i className="fa-solid fa-chevron-right"></i>
                            </Link>
                        </div>
                        <div className="ListOfRedirects">
                            <Link reloadDocument to={window.location.origin + "/bones"} onClick={Animation}>
                                <p>Bones</p>
                                <i className="fa-solid fa-chevron-right"></i>
                            </Link>
                        </div>
                        <div className="ListOfRedirects">
                            <Link reloadDocument to={window.location.origin + "/calcas"} onClick={Animation}>
                                <p>Calças</p>
                                <i className="fa-solid fa-chevron-right"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="BottomNavigationComponent">

                    </div>
                </nav>
                :
                null
            }
        </React.Fragment>
    )
}

export default NavigatorComponent;