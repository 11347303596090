/**
 * Creation Date: 22/08/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import { useEffect, useState } from "react";
import { getItemById } from "../../../lib/database";

const ItemCart = ({ content, removepos, index }) => {

    const [itemAtual, setItem] = useState(null);
    const [Loading, setLoading] = useState(false);
    const [photos, setPhotos] = useState(null);

    async function requestItem() {
        setLoading(true);
        try {
            const itemAtual = await getItemById(content.id);
            setItem(itemAtual)
            setPhotos(JSON.parse(itemAtual.photos))
            setLoading(false);
            
        }
        catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        requestItem()
    }, [])

    if (itemAtual) {
        return (
            <li>
                <div className="SacolaShow_PhotoURL__sacola--item">
                    <img className="SacolaShow_PhotoURL_sacola--item" src={photos && photos.length > 0 ? photos[0] : photos} alt="" />
                </div>
                <div className="SacolaShow-ContentInformation--item">
                    <div className="HeaderSacolaShow--item">
                        <h3>{itemAtual.name}</h3>
                        <div className="HeaderButtonRemove">
                            <button className="ButtonInnerRemove" onClick={() => { removepos(index) }}>
                                <span><i className="fa-regular fa-trash-can"></i></span>
                            </button>
                        </div>
                    </div>
                    <div className="SacolasTamanho_Cor">
                        <h4>Tamanho: <span>{content.size}</span></h4>
                        <h4>Cor: <span>{content.cor}</span></h4>
                    </div>
                    <div className="PrecosSacola">
                        {itemAtual.desconto > 0 ?
                            <>
                                <label className="priceatual">R${(itemAtual.price - itemAtual.desconto).toFixed(2)}</label>
                                <s>
                                    <label className="desconto">R${(itemAtual.price).toFixed(2)}</label>
                                </s>
                            </>
                            :
                            <label className="priceatual">R${(itemAtual.price).toFixed(2)}</label>
                        }
                    </div>
                </div>
            </li>
        )
    }
}

export default ItemCart;