/**
 * Creation Date: 22/08/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import React, { useEffect, useState } from "react"
import CardProduct from "../productpage/CardProduct";
import { Link } from "react-router-dom";

import BannerCamiseta from '../../images/Camisetas-Banner.webp'
import BannerMoletons from '../../images/MoletonsBanner.webp'
import BannerBones from '../../images/BonesBanner.webp'
import BannerCalcas from '../../images/CalcasBanner.webp'
import WhatsAppRedirect from "../mainpage/WhatsAppRedirect";
import BannerCortaVentos from '../../images/BannerCortaVentos.webp'

const ProductListShow = ({ type, products }) => {
    const [Size, setSize] = useState(null);
    const [Price, setPrice] = useState(100);
    const [PriceMax, setPriceMax] = useState(100);
    const [Color, setColor] = useState(null);
    const [FilterIsOpen, setFilterIsOpen] = useState(false);

    useEffect(() => {

        if (products.length > 0) {
            const maxPriceProduct = products.reduce((prev, curr) => {
                return (prev.price > curr.price) ? prev : curr;
            });

            setPriceMax(maxPriceProduct.price);
            setPrice(maxPriceProduct.price)
        }

        const handleResize = () => {
            if (window.innerWidth > 768) {
                setFilterIsOpen(true);
            } else {
                setFilterIsOpen(false);
            }
        };
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);

    }, [])

    function RemoveFilter() {
        setColor(null);
        setSize(null);
    }

    return (
        <React.Fragment>
            <section className="bannerPrimary-Category">
                <div className="redirect">
                    <h3>Você está em <Link reloadDocument to={window.location.origin}>CTR</Link> <i className="fa-solid fa-chevron-right"></i> <Link reloadDocument>{type}</Link></h3>
                </div>
                {type == "Camisetas" && <img src={BannerCamiseta} />}
                {type == "Moletons" && <img src={BannerMoletons} />}
                {type == "Corta-Ventos" && <img src={BannerCortaVentos} />}
                {type == "Bones" && <img src={BannerBones} />}
                {type == "Calças" && <img src={BannerCalcas} />}
            </section>
            <section className="ProductListShow">

                {type === "Camisetas" &&
                    <section section className="FilterComponent" >
                        <div className="Title-Component-Filter">
                            <h2>Filtros</h2>
                            {FilterIsOpen ?
                                <button onClick={() => setFilterIsOpen(false)} className="Btn-Filter"><i className="fa-solid fa-minus"></i><span></span></button>
                                :
                                <button onClick={() => setFilterIsOpen(true)} className="Btn-Filter"><i className="fa-solid fa-plus"></i><span></span></button>
                            }
                        </div>
                        {FilterIsOpen &&
                            <React.Fragment>
                                <div className="SectionFilter">
                                    <div className="FilterTitle">
                                        <h1><i className="fa-solid fa-money-bill-wave"></i> Preços</h1>
                                    </div>
                                    <input value={Price} onChange={(e) => { setPrice(e.target.value) }} type="range" min={0} max={PriceMax + 1} />
                                    <p>R$ 0,00 até R$ {Price}</p>

                                </div>
                                <div className="SectionFilter">
                                    <div className="FilterTitle">
                                        <h1><i className="fa-solid fa-palette"></i> Cor</h1>
                                    </div>
                                    <div className="OptionsContent">
                                        <div className="OptionWrapColor">
                                            <button id={Color === "Preto" && 'selected'} style={{ backgroundColor: '#000' }} onClick={() => setColor("Preto")} className="Option--inner">
                                            </button>
                                        </div>
                                        <div className="OptionWrapColor">
                                            <button id={Color === "Branca" && 'selected'} style={{ backgroundColor: 'white' }} onClick={() => setColor("Branca")} className="Option--inner">
                                            </button>
                                        </div>
                                        <div className="OptionWrapColor">
                                            <button id={Color === "Rosa" && 'selected'} style={{ backgroundColor: '#DA2E86' }} onClick={() => setColor("Rosa")} className="Option--inner">
                                            </button>
                                        </div>
                                        <div className="OptionWrapColor">
                                            <button id={Color === "Roxo" && 'selected'} style={{ backgroundColor: '#6452b8' }} onClick={() => setColor("Roxo")} className="Option--inner">
                                            </button>
                                        </div>
                                        <div className="OptionWrapColor">
                                            <button id={Color === "Bege" && 'selected'} style={{ backgroundColor: '#E8DFC0' }} onClick={() => setColor("Bege")} className="Option--inner">
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                <div className="SectionFilter">
                                    <div className="FilterTitle">
                                        <h1><i className="fa-solid fa-pen-nib"></i> Tamanho</h1>
                                    </div>
                                    <div className="OptionsContent">
                                        <div className="OptionWrap">
                                            <button id={Size === "P" && 'selected'} onClick={() => setSize("P")} className="Option--inner">
                                                <span>P</span>
                                            </button>
                                        </div>
                                        <div className="OptionWrap">
                                            <button id={Size === "M" && 'selected'} onClick={() => setSize("M")} className="Option--inner">
                                                <span>M</span>
                                            </button>
                                        </div>
                                        <div className="OptionWrap">
                                            <button id={Size === "G" && 'selected'} onClick={() => setSize("G")} className="Option--inner">
                                                <span>G</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <>
                                    {Size || Color ?
                                        <section className="SectionFilter">
                                            <button onClick={RemoveFilter} className="ButtonRemove">REMOVER FILTROS</button>
                                        </section>
                                        :
                                        null
                                    }
                                </>
                            </React.Fragment>
                        }

                    </section>
                }

                {type === "Moletons" &&
                    <section section className="FilterComponent" >
                        <div className="Title-Component-Filter">
                            <h2>Filtros</h2>
                            {FilterIsOpen ?
                                <button onClick={() => setFilterIsOpen(false)} className="Btn-Filter"><i className="fa-solid fa-minus"></i><span></span></button>
                                :
                                <button onClick={() => setFilterIsOpen(true)} className="Btn-Filter"><i className="fa-solid fa-plus"></i><span></span></button>
                            }
                        </div>
                        {FilterIsOpen &&
                            <React.Fragment>
                                <div className="SectionFilter">
                                    <div className="FilterTitle">
                                        <h1><i className="fa-solid fa-money-bill-wave"></i> Preços</h1>
                                    </div>
                                    <input value={Price} onChange={(e) => { setPrice(e.target.value) }} type="range" min={0} max={PriceMax + 1} />
                                    <p>R$ 0,00 até R$ {Price}</p>

                                </div>
                                <div className="SectionFilter">
                                    <div className="FilterTitle">
                                        <h1><i className="fa-solid fa-palette"></i> Cor</h1>
                                    </div>
                                    <div className="OptionsContent">
                                        <div className="OptionWrapColor">
                                            <button id={Color === "Preto" && 'selected'} style={{ backgroundColor: '#000' }} onClick={() => setColor("Preto")} className="Option--inner">
                                            </button>
                                        </div>
                                        <div className="OptionWrapColor">
                                            <button id={Color === "Branca" && 'selected'} style={{ backgroundColor: 'white' }} onClick={() => setColor("Branca")} className="Option--inner">
                                            </button>
                                        </div>

                                    </div>

                                </div>
                                <div className="SectionFilter">
                                    <div className="FilterTitle">
                                        <h1><i className="fa-solid fa-pen-nib"></i> Tamanho</h1>
                                    </div>
                                    <div className="OptionsContent">
                                        <div className="OptionWrap">
                                            <button id={Size === "P" && 'selected'} onClick={() => setSize("P")} className="Option--inner">
                                                <span>P</span>
                                            </button>
                                        </div>
                                        <div className="OptionWrap">
                                            <button id={Size === "M" && 'selected'} onClick={() => setSize("M")} className="Option--inner">
                                                <span>M</span>
                                            </button>
                                        </div>
                                        <div className="OptionWrap">
                                            <button id={Size === "G" && 'selected'} onClick={() => setSize("G")} className="Option--inner">
                                                <span>G</span>
                                            </button>
                                        </div>
                                        <div className="OptionWrap">
                                            <button id={Size === "GG" && 'selected'} onClick={() => setSize("GG")} className="Option--inner">
                                                <span>GG</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <>
                                    {Size || Color ?
                                        <section className="SectionFilter">
                                            <button onClick={RemoveFilter} className="ButtonRemove">REMOVER FILTROS</button>
                                        </section>
                                        :
                                        null
                                    }
                                </>
                            </React.Fragment>
                        }

                    </section>
                }

                {type === "Corta-Ventos" &&
                    <section section className="FilterComponent" >
                        <div className="Title-Component-Filter">
                            <h2>Filtros</h2>
                            {FilterIsOpen ?
                                <button onClick={() => setFilterIsOpen(false)} className="Btn-Filter"><i className="fa-solid fa-minus"></i><span></span></button>
                                :
                                <button onClick={() => setFilterIsOpen(true)} className="Btn-Filter"><i className="fa-solid fa-plus"></i><span></span></button>
                            }
                        </div>
                        {FilterIsOpen &&
                            <React.Fragment>
                                <div className="SectionFilter">
                                    <div className="FilterTitle">
                                        <h1><i className="fa-solid fa-money-bill-wave"></i> Preços</h1>
                                    </div>
                                    <input value={Price} onChange={(e) => { setPrice(e.target.value) }} type="range" min={0} max={PriceMax + 1} />
                                    <p>R$ 0,00 até R$ {Price}</p>

                                </div>
                                <div className="SectionFilter">
                                    <div className="FilterTitle">
                                        <h1><i className="fa-solid fa-palette"></i> Cor</h1>
                                    </div>
                                    <div className="OptionsContent">
                                        <div className="OptionWrapColor">
                                            <button id={Color === "Preto" && 'selected'} style={{ backgroundColor: '#000' }} onClick={() => setColor("Preto")} className="Option--inner">
                                            </button>
                                        </div>
                                        <div className="OptionWrapColor">
                                            <button id={Color === "Branca" && 'selected'} style={{ backgroundColor: 'white' }} onClick={() => setColor("Branca")} className="Option--inner">
                                            </button>
                                        </div>

                                    </div>

                                </div>
                                <div className="SectionFilter">
                                    <div className="FilterTitle">
                                        <h1><i className="fa-solid fa-pen-nib"></i> Tamanho</h1>
                                    </div>
                                    <div className="OptionsContent">
                                        <div className="OptionWrap">
                                            <button id={Size === "P" && 'selected'} onClick={() => setSize("P")} className="Option--inner">
                                                <span>P</span>
                                            </button>
                                        </div>
                                        <div className="OptionWrap">
                                            <button id={Size === "M" && 'selected'} onClick={() => setSize("M")} className="Option--inner">
                                                <span>M</span>
                                            </button>
                                        </div>
                                        <div className="OptionWrap">
                                            <button id={Size === "G" && 'selected'} onClick={() => setSize("G")} className="Option--inner">
                                                <span>G</span>
                                            </button>
                                        </div>
                                        
                                    </div>
                                </div>

                                <>
                                    {Size || Color ?
                                        <section className="SectionFilter">
                                            <button onClick={RemoveFilter} className="ButtonRemove">REMOVER FILTROS</button>
                                        </section>
                                        :
                                        null
                                    }
                                </>
                            </React.Fragment>
                        }

                    </section>
                }

                {type === "Bones" &&
                    <section section className="FilterComponent" >
                        <div className="Title-Component-Filter">
                            <h2>Filtros</h2>
                            {FilterIsOpen ?
                                <button onClick={() => setFilterIsOpen(false)} className="Btn-Filter"><i className="fa-solid fa-minus"></i><span></span></button>
                                :
                                <button onClick={() => setFilterIsOpen(true)} className="Btn-Filter"><i className="fa-solid fa-plus"></i><span></span></button>
                            }
                        </div>
                        {FilterIsOpen &&
                            <React.Fragment>
                                <div className="SectionFilter">
                                    <div className="FilterTitle">
                                        <h1><i className="fa-solid fa-money-bill-wave"></i> Preços</h1>
                                    </div>
                                    <input value={Price} onChange={(e) => { setPrice(e.target.value) }} type="range" min={0} max={PriceMax + 1} />
                                    <p>R$ 0,00 até R$ {Price}</p>

                                </div>
                                <div className="SectionFilter">
                                    <div className="FilterTitle">
                                        <h1><i className="fa-solid fa-palette"></i> Cor</h1>
                                    </div>
                                    <div className="OptionsContent">

                                        <div className="OptionWrapColor">
                                            <button id={Color === "Branca" && 'selected'} style={{ backgroundColor: 'white' }} onClick={() => setColor("Branca")} className="Option--inner">
                                            </button>
                                        </div>

                                    </div>

                                </div>
                                <div className="SectionFilter">
                                    <div className="FilterTitle">
                                        <h1><i className="fa-solid fa-pen-nib"></i> Tamanho</h1>
                                    </div>
                                    <div className="OptionsContent">
                                        <div className="OptionWrap">
                                            <button id={Size === "Único" && 'selected'} onClick={() => setSize("Único")} className="Option--inner">
                                                <span>Único</span>
                                            </button>
                                        </div>

                                    </div>
                                </div>

                                <>
                                    {Size || Color ?
                                        <section className="SectionFilter">
                                            <button onClick={RemoveFilter} className="ButtonRemove">REMOVER FILTROS</button>
                                        </section>
                                        :
                                        null
                                    }
                                </>
                            </React.Fragment>
                        }

                    </section>
                }

                {type === "Calças" &&
                    <section section className="FilterComponent" >
                        <div className="Title-Component-Filter">
                            <h2>Filtros</h2>
                            {FilterIsOpen ?
                                <button onClick={() => setFilterIsOpen(false)} className="Btn-Filter"><i className="fa-solid fa-minus"></i><span></span></button>
                                :
                                <button onClick={() => setFilterIsOpen(true)} className="Btn-Filter"><i className="fa-solid fa-plus"></i><span></span></button>
                            }
                        </div>
                        {FilterIsOpen &&
                            <React.Fragment>
                                <div className="SectionFilter">
                                    <div className="FilterTitle">
                                        <h1><i className="fa-solid fa-money-bill-wave"></i> Preços</h1>
                                    </div>
                                    <input value={Price} onChange={(e) => { setPrice(e.target.value) }} type="range" min={0} max={PriceMax + 1} />
                                    <p>R$ 0,00 até R$ {Price}</p>

                                </div>
                                <div className="SectionFilter">
                                    <div className="FilterTitle">
                                        <h1><i className="fa-solid fa-palette"></i> Cor</h1>
                                    </div>
                                    <div className="OptionsContent">
                                        <div className="OptionWrapColor">
                                            <button id={Color === "Preto" && 'selected'} style={{ backgroundColor: '#000' }} onClick={() => setColor("Preto")} className="Option--inner">
                                            </button>
                                        </div>
                                        <div className="OptionWrapColor">
                                            <button id={Color === "Branca" && 'selected'} style={{ backgroundColor: 'white' }} onClick={() => setColor("Branca")} className="Option--inner">
                                            </button>
                                        </div>

                                    </div>

                                </div>
                                <div className="SectionFilter">
                                    <div className="FilterTitle">
                                        <h1><i className="fa-solid fa-pen-nib"></i> Tamanho</h1>
                                    </div>
                                    <div className="OptionsContent">
                                        <div className="OptionWrap">
                                            <button id={Size === "P" && 'selected'} onClick={() => setSize("P")} className="Option--inner">
                                                <span>P</span>
                                            </button>
                                        </div>
                                        <div className="OptionWrap">
                                            <button id={Size === "M" && 'selected'} onClick={() => setSize("M")} className="Option--inner">
                                                <span>M</span>
                                            </button>
                                        </div>
                                        <div className="OptionWrap">
                                            <button id={Size === "G" && 'selected'} onClick={() => setSize("G")} className="Option--inner">
                                                <span>G</span>
                                            </button>
                                        </div>
                                        <div className="OptionWrap">
                                            <button id={Size === "GG" && 'selected'} onClick={() => setSize("GG")} className="Option--inner">
                                                <span>GG</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <>
                                    {Size || Color ?
                                        <section className="SectionFilter">
                                            <button onClick={RemoveFilter} className="ButtonRemove">REMOVER FILTROS</button>
                                        </section>
                                        :
                                        null
                                    }
                                </>
                            </React.Fragment>
                        }

                    </section>
                }

                {type === "Novidades" &&
                    <section section className="FilterComponent" >
                        <div className="Title-Component-Filter">
                            <h2>Filtros</h2>
                            {FilterIsOpen ?
                                <button onClick={() => setFilterIsOpen(false)} className="Btn-Filter"><i className="fa-solid fa-minus"></i><span></span></button>
                                :
                                <button onClick={() => setFilterIsOpen(true)} className="Btn-Filter"><i className="fa-solid fa-plus"></i><span></span></button>
                            }
                        </div>
                        {FilterIsOpen &&
                            <React.Fragment>
                                <div className="SectionFilter">
                                    <div className="FilterTitle">
                                        <h1><i className="fa-solid fa-money-bill-wave"></i> Preços</h1>
                                    </div>
                                    <input value={Price} onChange={(e) => { setPrice(e.target.value) }} type="range" min={0} max={PriceMax + 1} />
                                    <p>R$ 0,00 até R$ {Price}</p>

                                </div>
                                <div className="SectionFilter">
                                    <div className="FilterTitle">
                                        <h1><i className="fa-solid fa-palette"></i> Cor</h1>
                                    </div>
                                    <div className="OptionsContent">
                                        <div className="OptionWrapColor">
                                            <button id={Color === "Preto" && 'selected'} style={{ backgroundColor: '#000' }} onClick={() => setColor("Preto")} className="Option--inner">
                                            </button>
                                        </div>
                                        <div className="OptionWrapColor">
                                            <button id={Color === "Branca" && 'selected'} style={{ backgroundColor: 'white' }} onClick={() => setColor("Branca")} className="Option--inner">
                                            </button>
                                        </div>
                                        <div className="OptionWrapColor">
                                            <button id={Color === "Rosa" && 'selected'} style={{ backgroundColor: '#DA2E86' }} onClick={() => setColor("Rosa")} className="Option--inner">
                                            </button>
                                        </div>
                                        <div className="OptionWrapColor">
                                            <button id={Color === "Bege" && 'selected'} style={{ backgroundColor: '#E8DFC0' }} onClick={() => setColor("Bege")} className="Option--inner">
                                            </button>
                                        </div>



                                    </div>

                                </div>
                                <div className="SectionFilter">
                                    <div className="FilterTitle">
                                        <h1><i className="fa-solid fa-pen-nib"></i> Tamanho</h1>
                                    </div>
                                    <div className="OptionsContent">
                                        <div className="OptionWrap">
                                            <button id={Size === "P" && 'selected'} onClick={() => setSize("P")} className="Option--inner">
                                                <span>P</span>
                                            </button>
                                        </div>
                                        <div className="OptionWrap">
                                            <button id={Size === "M" && 'selected'} onClick={() => setSize("M")} className="Option--inner">
                                                <span>M</span>
                                            </button>
                                        </div>
                                        <div className="OptionWrap">
                                            <button id={Size === "G" && 'selected'} onClick={() => setSize("G")} className="Option--inner">
                                                <span>G</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <>
                                    {Size || Color ?
                                        <section className="SectionFilter">
                                            <button onClick={RemoveFilter} className="ButtonRemove">REMOVER FILTROS</button>
                                        </section>
                                        :
                                        null
                                    }
                                </>
                            </React.Fragment>
                        }

                    </section>
                }

                <section className="ShowComponentProducts">
                    <div className="titleComponentProducts">
                        <h1>{type}</h1>
                        <p><i className="fa-solid fa-box"></i> {Array.isArray(products) && products.length} produtos</p>
                    </div>
                    <div className="ListProducts">
                        <React.Fragment>
                            {Array.isArray(products) && products.map((product) => {

                                if (Price < product.price) {
                                    return null;
                                }

                                if (Size && !JSON.parse(product.sizes).includes(Size)) {
                                    return null;
                                }

                                if (Color && !JSON.parse(product.color).includes(Color)) {
                                    return null;
                                }

                                return (
                                    <CardProduct
                                        product={product}
                                    />
                                )
                            })}
                        </React.Fragment>
                    </div>
                </section>
            </section>
            <WhatsAppRedirect />
        </React.Fragment>
    )

}

export default ProductListShow