/**
 * Creation Date: 25/02/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import { Link, useFetcher, useNavigate } from "react-router-dom";
import { LimparSacola, createOrder, defineSacolaUser, getAddressUsers, getCurrentUser, getItemById, getSacolaUser } from "../lib/database";
import { account } from "../lib/appwrite";
import '../style/checkout.css';
import LogoSemProdutos from '../images/undraw_empty_cart_co35.svg'
import Swal from "sweetalert2";
import { auth } from "../lib/firebase";

const { useState, useEffect } = require("react");
const { default: Loader } = require("../components/Loading");

const Checkout = () => {

    const Nav = useNavigate()
    const [isClosing, setIsClosing] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [sacolaUser, setSacolaUser] = useState(null);

    const [arrayCart, setArrayCarrinho] = useState(null);

    const [metodoPay, setMetodoPay] = useState(null);

    const [Subtotal, setSubtotal] = useState(0);
    const [Desconto, setDesconto] = useState(0);
    const [Total, setTotal] = useState(0);
    const [totalPayPix, setTotalPayPix] = useState(0);

    const [ContatoTelefone, setContatoTelefone] = useState(null)

    const [enderecosOutPut, setenderecosOutPut] = useState([]);
    const [QtdEnd, setQtdEnd] = useState(0)
    const [enderecos, setenderecos] = useState([])


    const [enderecoSelected, setEnderecoSelected] = useState([])

    const [WrapperSacola, setWrapperSacola] = useState(null);

    const [currentSelected, setCurrentSelected] = useState(null)

    const [Step, setStep] = useState(1)

    async function getAccount() {
        setLoading(true);
        try {
                const data = await getCurrentUser(auth.currentUser.uid)
                setCurrentUser(data);
                requestSacola(data.uid);

                setLoading(false);
        }
        catch (error) {

            return setLoading(false);

        }
    }

    useEffect(() => {
        getAccount()
    }, [auth.currentUser])

    useEffect(() => {
        getSacolaItens()
    }, [sacolaUser])

    useEffect(() => {
        if (currentUser) {
            getEnd(currentUser.uid)
        }
    })

    async function getSacolaItens() {
        if (sacolaUser) {
            const promises = sacolaUser.map(async (res, i) => {
                const itemDB = await getItemById(res.id);
                return { itemDB, res, index: i };
            });

            const array = await Promise.all(promises);
            setArrayCarrinho(array);
        }
    }

    async function getEnd(uid) {
        const ad = await getAddressUsers(uid) || [];
        const AddressUser = JSON.parse(ad.adress);
        setQtdEnd(AddressUser.length)

        setenderecosOutPut(
            AddressUser.map((e, i) => {

                function checkMark() {
                    if (currentSelected == i) {
                        return 'selected'
                    }
                    else {
                        return null
                    }
                }

                return (
                    <li id={checkMark()} onClick={() => {
                        setCurrentSelected(i);
                        setEnderecoSelected({
                            "CEP": e.cep,
                            "Endereco": e.endereco,
                            "Complemento_Ref": e.complemento_e_ref,
                            "Destinatario": e.destinatario,
                            "Estado": e.estado,
                            "Cidade": e.cidade,
                            "Bairro": e.bairro,
                            "Numero": e.numero,
                        })


                    }} className="EnderecoWrapper--item">

                        <div className="EnderecoWrapper-inside">
                            <p>{checkMark() == "selected" ? <span><i className="fa-solid fa-check"></i></span> : <span></span>} <i className="fa-solid fa-house"></i> {e.endereco}</p>
                            <p>{e.bairro} - {e.cidade} - {e.estado}</p>
                        </div>
                    </li>
                )
            })
        )
    }



    async function requestSacola(uid) {
        const sacola = await getSacolaUser(uid);

        try {
            const newSacola = JSON.parse(sacola.items);
            setSacolaUser(newSacola);
            getPrices(newSacola)
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    }


    async function removepos(index) {
        try {
            const newSacola = [...sacolaUser];
            newSacola.splice(index, 1);
            setLoading(true);
            await defineSacolaUser(currentUser.uid, newSacola);

            setSacolaUser(newSacola)
            setLoading(false);
            getPrices(newSacola);
            window.location.reload()
        } catch (error) {
            console.error("Erro ao remover item:", error);
            setLoading(false);
        }
    }

    async function getPrices(itens) {
        let sub = 0;
        let desc = 0;


        await Promise.all(
            itens.map(async (item) => {
                const itemDB = await getItemById(item.id);

                sub += itemDB.price;
                desc += itemDB.desconto;
            })
        );


        setSubtotal(sub);
        setDesconto(desc);
        setTotal(sub - desc);
        setTotalPayPix(((sub - desc) * 0.95))
    }

    function changeStep(steptoChange) {
        if (Step > steptoChange) {
            setStep(steptoChange)
        }
    }

    async function checkoutOrder() {
        if (enderecoSelected && ContatoTelefone && sacolaUser && currentUser) {
            setLoading(true);

            await createOrder(currentUser.uid, currentUser, sacolaUser, Total, enderecoSelected, metodoPay)
                .then(async (res) => {
                    try {
                        await LimparSacola(currentUser.uid);
                    }
                    catch (error) {
                        console.log("Erro ao limpar carrinho.")
                    }
                    finally {
                        Nav('/orders/sucess'); //IR PARA PÁGINA DE SUCESSO
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    return Swal.fire({
                        title: "ERRO!",
                        text: "Por favor, insira todas informações solicitadas.",
                    });
                })

        }
        else {
            alert("Complete todos os steps");
        }
    }
    return (
        <>
            {Loading ? <Loader /> : null}
            <section className="CheckoutPage-Content-Wrapper">
                <div className="CheckoutPage-Products-Show">
                    {sacolaUser && sacolaUser.length > 0 ?
                        <h2>Resumo do pedido</h2>
                        :
                        null}
                    <div className="SacolaCheckoutPage">
                        {sacolaUser && sacolaUser.length > 0 ?
                            <>
                                {arrayCart && arrayCart.map((item) => {

                                    const photos = JSON.parse(item.itemDB.photos)

                                    return (
                                        <div className="SacolaShow--item">
                                            <div className="leftSideSacola-show">
                                                <div className="SacolaImage--image">
                                                    <img src={photos && photos.length > 1 ? photos[0] : photos} />
                                                </div>
                                                <div className="SacolaContent--name">
                                                    <h3>{item.itemDB.name}</h3>
                                                    <p>Cor: {item.res.cor}</p>
                                                    <p>Tamanho: {item.res.size}</p>
                                                    {item.itemDB.desconto > 0 ?
                                                        <p><s className="desconto">R${(item.itemDB.price).toFixed(2)}</s> <span className="priceatual">R${(item.res.price - item.res.desconto).toFixed(2)}</span></p>
                                                        :
                                                        <p className="priceatual">R${(item.itemDB.price - item.itemDB.desconto).toFixed(2)}</p>
                                                    }

                                                </div>
                                            </div>
                                            <div className="rightSideSacola-show">
                                                <button onClick={() => { removepos(item.index) }} className="ButtonSacola--remove"><i className="fa-solid fa-trash-can"></i> <span className="pconly">REMOVA ESSE PRODUTO</span></button>
                                            </div>
                                        </div>
                                    )
                                })}

                            </> :
                            <div className="empty-Sacola-Show">
                                <img src={LogoSemProdutos} />
                                <h1>Adicione produtos na sacola</h1>
                                <p>Você ainda não tem nenhum produto na sacola!</p>
                                <Link reloadDocument to="/"><button type="submit" className="btn btn-primary">Voltar para a loja</button></Link>
                            </div>
                        }

                    </div>

                </div>


                {sacolaUser && sacolaUser.length > 0 ?
                    <>
                        <div className="CheckoutPage-Steps-To-Finish">
                            <div id={Step == 1 ? "enabled" : "disabled"} onClick={() => { changeStep(1) }} className="CheckoutPage-Steps-LeftSide CheckoutStep">
                                <div className="CheckoutPage--step">
                                    <h2><i className="fa-solid fa-circle-user"></i> Dados pessoais</h2>
                                    <p>Solicitamos apenas as informações essenciais para a realização da compra.</p>
                                    {Step == 1 ?
                                        <>
                                            <div className="Inputs-Content">
                                                <div className="LeftSide">
                                                    <div className="CheckoutPage-InputBox">
                                                        <p>Nome completo</p>
                                                        <input value={currentUser ? currentUser.nome_completo : null} disabled type="text" />
                                                    </div>
                                                    <div className="CheckoutPage-InputBox">
                                                        <p>CPF</p>
                                                        <input value={currentUser ? currentUser.cpf : null} disabled type="number" />
                                                    </div>
                                                </div>
                                                <div className="RightSide">
                                                    <div className="CheckoutPage-InputBox">
                                                        <p>Número do WhatsApp ou Telefone *</p>
                                                        <input value={ContatoTelefone} onChange={(e) => { setContatoTelefone(e.target.value) }} type="tel" />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        null
                                    }
                                </div>

                                {Step == 1 ?
                                    <>
                                        {ContatoTelefone ?
                                            <div className="buttonContinue">
                                                <button onClick={() => { if (ContatoTelefone) { setStep(2) } }}>CONTINUAR</button>
                                            </div>
                                            :
                                            null
                                        }
                                    </>
                                    :
                                    null
                                }

                            </div>

                            <div id={Step == 2 ? "enabled" : "disabled"} onClick={() => { changeStep(2) }} className="CheckoutPage-Steps-Center CheckoutStep">
                                <div className="CheckoutPage--step">
                                    <h2><i className="fa-solid fa-truck"></i> Entrega</h2>
                                    <p>Escolha um endereço para o recebimento dos produtos.</p>

                                    {Step == 2 ?
                                        <>
                                            {QtdEnd > 0 ?
                                                <ul className="listsEnderecos-Checkout">
                                                    {enderecosOutPut}
                                                </ul>
                                                :
                                                <div className="EnderecosExists--CheckOut">
                                                    <p>Parece que você ainda não adicionou nenhum endereço para sua conta.</p>
                                                    <button onClick={() => { window.location.href = window.location.origin + "/accounts/myaccount/address" }}>ADICIONAR ENDEREÇO</button>
                                                </div>
                                            }
                                            <div className="buttonContinue">

                                                <button onClick={() => {
                                                    if (enderecoSelected.CEP) { setStep(3) }
                                                }}>CONTINUAR</button>
                                            </div>
                                        </>
                                        :
                                        null
                                    }
                                </div>
                            </div>


                            <div id={Step == 3 ? "enabled" : "disabled"} className="CheckoutPage-Steps-RightSide CheckoutStep">
                                <div className="CheckoutPage--step">
                                    <h2><i className="fa-solid fa-credit-card"></i> Pagamento</h2>
                                    <p>Para finalizar, selecione o método para realizar o pagamento.</p>
                                    {Step == 3 ?
                                        <>
                                            <div className="totalPedido">
                                                <h3>Subtotal: R${Subtotal.toFixed(2)}</h3>
                                                <h3>Descontos: R${Desconto.toFixed(2)}</h3>
                                                <h2>Total: R${Total.toFixed(2)} ou <span className="greenoff">R${totalPayPix.toFixed(2)} pagando pelo Pix</span></h2>
                                            </div>

                                            <div className="CheckBox-Payment">
                                                <button id={metodoPay == "Pix" ? "selectedButtonWrapper" : null} onClick={() => { setMetodoPay("Pix") }}>Pix</button>
                                                <button id={metodoPay == "Cartao" ? "selectedButtonWrapper" : null} onClick={() => { setMetodoPay('Cartao') }}>Cartão de crédito</button>
                                            </div>
                                            {metodoPay == "Pix" ?
                                                <div className="PaymentPix">
                                                    <div>
                                                        <div className="codeQRCODE">
                                                            <p>Ao realizar o pagamento, envie entre em contato via WhatsApp ou Instagram e encaminhe o comprovante.</p>
                                                            <input disabled value={"35999553467"} />
                                                            <button onClick={() => { navigator.clipboard.writeText("35999553467") }}>COPIAR</button>
                                                        </div>
                                                    </div>
                                                    <div className="paymentButtonWrapper">
                                                        <button onClick={checkoutOrder}><i className="fa-solid fa-cart-shopping"></i> FINALIZAR COMPRA</button>
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    {metodoPay == "Cartao" ?
                                                        <div className="PaymentCreditCard">
                                                            <p>.</p>
                                                            <div className="paymentButtonWrapper">
                                                                <button onClick={checkoutOrder}><i className="fa-solid fa-cart-shopping"></i> FINALIZAR COMPRA</button>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </>
                                            }
                                        </>
                                        :
                                        null
                                    }

                                </div>
                            </div>
                        </div>
                    </>
                    :
                    null
                }
            </section>

        </>
    )
}


export default Checkout