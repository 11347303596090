/**
 * Creation Date: 22/08/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { account } from "../../lib/appwrite";
import Swal from "sweetalert2";
import Loader from "../../components/Loading";
import '../../style/auth.css';
import LogoHeader from '../../images/logo-header.webp';
import Banner from '../../images/image-loggin.webp'
import { auth } from "../../lib/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

export default function Login() {

    const [Email, setEmail] = useState(null);
    const [Password, setPassword] = useState(null);
    const [Loading, setLoading] = useState(false)

    async function getAccount() {
        setLoading(true)
        if (auth.currentUser) {
            window.location.href = window.location.origin
        }
        setLoading(false)
    }

    useEffect(() => {
        getAccount()
    }, [auth.currentUser])

    async function loginAccount(e) {
        e.preventDefault()
        if (!Email || !Password) {
            return Swal.fire({
                title: "ERRO!",
                text: "Por favor, insira todas informações solicitadas.",
            });
        }

        try {
            signInWithEmailAndPassword(auth, Email, Password)
                .then((res) => {
                    if (window.location.href.includes("checkout_continue")) {
                        window.location.href = window.location.origin + "/checkout"
                    }
                    else {
                        window.location.href = window.location.origin
                    }
                })
                .catch((error) => {
                    console.error(error)
                    return Swal.fire({
                        title: "ERRO!",
                        text: "Erro de senha/e-mail - por favor, confira as informações e tente novamente",
                    });
                })
        }
        catch (error) {

        }
    }

    return (
        <>
            {Loading ? <Loader /> : null}
            <div className="LoginPageContent">
                <section className="Login-Content-Box-Temp">
                    <div className="LoginPage_BannerLeftSide-content">
                        <div className="fixedHeader"><h1>COM TODO RESPEITO</h1></div>
                        <img src={Banner} />
                    </div>
                    <div className="LoginPage_Content-Login--box">
                        <div className="HeaderLoginContent">
                            <img src={LogoHeader} />
                            <h2>REALIZE SEU LOGIN</h2>
                        </div>
                        <form action="" id="loginForm">
                            <div className="BoxInput-inner-box">
                                <p>Endereço de e-mail</p>
                                <input onChange={(e) => { setEmail(e.target.value) }} type="email" placeholder="E-mail" />
                            </div>
                            <div className="BoxInput-inner-box">
                                <p>Senha de acesso</p>
                                <input onChange={(e) => { setPassword(e.target.value) }} type="password" placeholder="Senha" />
                            </div>
                            <div className="BoxButton-Confirm">
                                <button onClick={loginAccount}>
                                    <span>ENTRAR NA CONTA</span>
                                </button>
                            </div>
                            <div className="RegisterLinkPage">
                                <p>Você ainda não possui uma conta registrada em nosso site?</p>
                                <Link reloadDocument to={window.location.origin + "/accounts/register"}>Cadastre-se aqui</Link>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </>
    )
}