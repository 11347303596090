/**
 * Creation Date: 22/08/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import { Link } from "react-router-dom";
import OneStar from '../../images/zero-stars.webp'
import { useState } from "react";

const CardProduct = ({ product, reload }) => {

    const [isHover, setHover] = useState(false);

    if (!product) {
        return null;
    }

    const Images = JSON.parse(product.photos);

    return (
        <div className="Product-Card-Wrapper">
            <section className="Section-Product-Card-Wrapper">
                <Link reloadDocument target={reload ? "_parent" : null} className="Product-Redirect" to={window.location.origin + "/produtos/" + product.id + "/" + product.url}>
                    <article className="Product-Card-Inside-Content">
                        <div className="Product-Image-Container">
                            <img
                                onMouseEnter={() => setHover(true)}
                                onMouseLeave={() => setHover(false)}
                                src={Images.length > 1 ? isHover ? Images[1] : Images[0] : Images} />
                        </div>
                        <div className="Product-Content-Data">
                            <div className="Product-Title">
                                <h3 className="Product-Title-Content">{product.name}</h3>
                            </div>
                            <div className="Product-Content-Stars">
                                <img src={OneStar} alt="Stars" />
                            </div>
                            <div className="Product-Pricing">
                                {product.desconto > 0 ?
                                    <h3><s style={{color: 'gray'}}>R$ {product.price.toFixed(2)}</s> <span style={{color: "red"}}>R$ {(product.price - product.desconto).toFixed(2)}</span></h3>
                                    :
                                    <h3 style={{color: "red"}}>R$ {product.price.toFixed(2)}</h3>}
                                {product.Vendas != 0 && <p>{product.Vendas} vendidos</p>}
                            </div>
                        </div>
                    </article>
                </Link>
            </section>
        </div>
    )

}

export default CardProduct;