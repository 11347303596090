/**
 * Creation Date: 22/08/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import HighImage from '../../../images/highimage-1.webp';
import HighImage2 from '../../../images/highimage-2.webp';
import HighImage3 from '../../../images/highimage-3.webp';
import HighImage4 from '../../../images/highimage-4.webp';
import HighImage5 from '../../../images/highimage-5.webp';

const HighImages = () => {

    return (
        <div className="HighImages">
            <div className="HighImages--content">
                <img src={HighImage} alt='High Image' />
                <img src={HighImage2} alt='High Image2' />
                <img src={HighImage3} alt='High Image3' />
                <img src={HighImage4} alt='High Image4' />
                <img src={HighImage5} alt='High Image5' />
                
            </div>
        </div>
    )
}
export default HighImages