/**
 * Creation Date: 22/08/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import { Link } from "react-router-dom";
import '../../style/footer.css';
import { useState } from "react";
import { addNewEmailNewsletter } from "../../lib/database";

const Footer = () => {

    const [email, setEmail] = useState(null);
    const [sucessMessage, setSucessMessage] = useState(false);

    const addEmailNewsletter = async () => {
        try {

            if(!email) {
                alert("Preencha todas informações");
            }
            else {
                await addNewEmailNewsletter(email);
                setSucessMessage(true);
            }

        }
        catch(error) {
            console.log("Erro ao adicionar email");
            alert("Erro ao cadastrar seu e-mail");
        }
        finally {
            setEmail("");
        }
        
    }

    return (
        <footer className="footerContent">
            <main className="footerMain">
                <div className="footerTopPage">
                    <div className="titleSide">
                        <h3>CADASTRE-SE E RECEBA NOSSAS</h3>
                        <h1>NOVIDADES</h1>
                        {sucessMessage && <p>Você cadastrou seu e-mail com sucesso!</p>}
                    </div>
                    
                    <div className="inputSide">
                        <input
                            placeholder="Nome"
                        />
                        <input
                            placeholder="E-mail"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                    </div>
                    <div className="btnSide">
                        <button
                            onClick={addEmailNewsletter}
                        >CADASTRAR</button>
                    </div>
                </div>
                <div className="footerMiddle">
                    <div className="footersideBottom">
                        <h3>ATENDIMENTO</h3>
                        <div className="optionside">
                            <div className="optionContent">
                                <Link reloadDocument
                                    to={"https://wa.me/5535999553467"}
                                    target="_blank"
                                >
                                    <i className="fa-brands fa-whatsapp"></i> WhatsApp - Vinícius Santos
                                </Link>
                            </div>
                            <div className="optionContent">
                                <Link reloadDocument
                                    to={"https://www.instagram.com/oficial.comtodorespeito/"}
                                    target="_blank"
                                >
                                    <i className="fa-brands fa-instagram"></i> Instagram
                                </Link>
                            </div>
                        </div>

                    </div>
                    <div className="footersideBottom">
                        <h3>INSTITUCIONAL</h3>
                        <div className="optionside">
                            <div>
                                <Link reloadDocument to={'/quem-somos'}>Quem Somos</Link>
                            </div>
                            <div>
                                <Link reloadDocument to={"https://chat.whatsapp.com/INcY3O2EKEOEzMkxURIhDR"} target="_blank">Comunidade oficial</Link>
                            </div>
                            <div>
                                <Link reloadDocument to={'/depoimentos-clientes'}>Depoimento de Clientes</Link>
                            </div>
                            <div>
                                <Link reloadDocument to={'/institucional/policia-devolucao'}>Política de Troca e Devoluções</Link>
                            </div>
                            <div>
                                <Link reloadDocument to={'/institucional/policia-privacidade'}>Política de Privacidade</Link>
                            </div>

                        </div>
                    </div>
                    <div className="footersideBottom">
                        <h3>FORMAS DE PAGAMENTO</h3>
                        <div className="optionside">
                            <div className="optionContent">
                                <img src={"https://images.tcdn.com.br/commerce/assets/store/img/icons/formas_pagamento/pag_peqpix.png?cbf90fe5b00bf21e4aed1a4c61e69916"} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footerBottom">
                    <p>COM TODO RESPEITO COMÉRCIO DE VESTUÁRIO - CTR Inc.® - Pouso Alegre, MG - Brasil</p>
                    <p>Hospedado por Hostinger</p>
                </div>
            </main>
        </footer>
    )
}

export default Footer;