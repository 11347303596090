/**
 * Creation Date: 22/08/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { account, databases } from "../../lib/appwrite";
import { ID } from "appwrite";
import Loader from "../../components/Loading";
import '../../style/auth.css';
import LogoHeader from '../../images/logo-header.webp';
import Banner from '../../images/highimage-2.webp'
import { auth } from "../../lib/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";


export default function Register() {

    const [Nome, setName] = useState(null);
    const [CPF, setCPF] = useState(null);
    const [Email, setEmail] = useState(null);
    const [Password, setPassword] = useState(null);
    const [Telefone, setTelefone] = useState(null);

    const [SucessBox, setSucessBox] = useState(null);

    const [Loading, setLoading] = useState(false);

    const endpoint = process.env.REACT_APP_API_ENDPOINT;
    //const endpoint = process.env.REACT_APP_API_ENDPOINT_TEST;
    const secretKey = process.env.REACT_APP_API_SECRET_KEY;

    async function getAccount() {
        setLoading(true)
        if (auth.currentUser) {
            window.location.href = window.location.origin
        }
        setLoading(false)

    }
    useEffect(() => {
        getAccount()
    }, [auth.currentUser])

    async function createAccount(e) {
        e.preventDefault()
        setLoading(true)
        if (!Nome || !CPF || !Email || !Password) {
            setLoading(false)
            return Swal.fire({
                title: "ERRO!",
                text: "Por favor, insira todas informações solicitadas.",
            });
        }
        try {
            if (auth.currentUser) {
                setLoading(false);
                window.location.href = window.location.origin;
            }
            else {
                //Criar usuário no banco de dados
                await createUserWithEmailAndPassword(auth, Email, Password)
                    .then(async (userCredential) => {
                        await fetch(`${endpoint}/api/v1/${secretKey}/users/create`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                uid: userCredential.user.uid,
                                label: "client",
                                role: "client",
                                nome: Nome,
                                email: Email,
                                photoURL: "https://static.vecteezy.com/system/resources/previews/009/292/244/non_2x/default-avatar-icon-of-social-media-user-vector.jpg",
                                telefone: Telefone,
                                cpf: CPF
                            }),
                        })
                            .then(async () => {
                                await fetch(`${endpoint}/api/v1/${secretKey}/sacolas/create`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify({
                                        id: userCredential.user.uid,
                                        items: "[]"
                                    }),
                                })
                                    .then(async () => {
                                        await fetch(`${endpoint}/api/v1/${secretKey}/adress/create`, {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json',
                                            },
                                            body: JSON.stringify({
                                                uid: userCredential.user.uid,
                                                adress: "[]"
                                            }),
                                        })
                                    })
                                    .then(async () => {
                                        await fetch(`${endpoint}/api/v1/${secretKey}/favorites/add`, {
                                            method: "POST",
                                            headers: {
                                                'Content-Type': 'application/json',
                                            },
                                            body: JSON.stringify({
                                                uid: userCredential.user.uid
                                            })
                                        })
                                    })

                                    .then((sucess) => {
                                        setLoading(false)
                                        setSucessBox(
                                            <div className="SucessBox-show">
                                                <h1>Sucesso!</h1>
                                                <p>Você criou sua conta com sucesso. Agora realize o login para continuar.</p>
                                                <Link reloadDocument to={window.location.origin + "/accounts/signin"}>Ir para página de login</Link>
                                            </div>
                                        )
                                    })

                            })
                    })
            }
        }
        catch (error) {
            setLoading(false)
            console.error(error)
        }
    }


    return (
        <>
            {Loading ? <Loader /> : null}
            <div className="LoginPageContent">
                <section className="Login-Content-Box-Temp">
                    <div className="LoginPage_BannerLeftSide-content">
                        <div className="fixedHeader"><h1>COM TODO RESPEITO</h1></div>
                        <img src={Banner} />
                    </div>
                    <div className="LoginPage_Content-Login--box">
                        <div className="HeaderLoginContent">
                            <img src={LogoHeader} />
                            <h2>CRIAÇÃO DE CONTA</h2>
                        </div>
                        {SucessBox}
                        <form action="" id="loginForm">
                            <div className="BoxInput-inner-box">
                                <p>Nome completo *</p>

                                <input required onChange={(e) => { setName(e.target.value) }} placeholder="Nome" type="text" />
                            </div>
                            <div className="BoxInput-inner-box">
                                <p>CPF *</p>
                                <input required onChange={(e) => { setCPF(e.target.value) }} placeholder="CPF" type="number" />
                            </div>
                            <div className="BoxInput-inner-box">
                                <p>Endereço de e-mail *</p>
                                <input required onChange={(e) => { setEmail(e.target.value) }} placeholder="E-mail" type="email" />
                            </div>
                            <div className="BoxInput-inner-box">
                                <p>Telefone *</p>
                                <input required onChange={(e) => { setTelefone(e.target.value) }} placeholder="Telefone" type="text" />
                            </div>
                            <div className="BoxInput-inner-box">
                                <p>Senha de acesso *</p>
                                <p>{Password}</p>
                                <input required onChange={(e) => { setPassword(e.target.value) }} placeholder="Senha" type="password" />
                            </div>
                            <div className="BoxButton-Confirm">
                                <button type="submit" onClick={createAccount}>
                                    <span>CRIAR CONTA</span>
                                </button>
                            </div>
                            <div className="RegisterLinkPage">
                                <p>Já possui uma conta cadastrada em nosso site?</p>
                                <Link reloadDocument to={window.location.origin + "/accounts/signin"}>Entre aqui</Link>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </>
    )
}