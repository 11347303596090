/**
 * Creation Date: 26/04/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { account, databases } from "../../../lib/appwrite";
import Navigation from "../../../components/accounts/Navigation";
import Loader from "../../../components/Loading";
import { getCurrentUser, getItemById, getOrdersOfUser } from "../../../lib/database";
import { auth } from "../../../lib/firebase";

export default function MyAccount_Orders() {

    const [userLogged, setUserlogged] = useState(null);
    const [usersOrders, setUserOrders] = useState(null);
    const [Loading, setLoading] = useState(false);


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    const data = await getCurrentUser(user.uid);
                    setUserlogged(data)
                    const orders = await getOrdersOfUser(data.uid);
                    setUserOrders(orders?.reverse());
                } catch (error) {
                    console.warn("Erro ao obter dados do usuário: ", error);
                } finally {
                    setLoading(false);
                }
            } else {
                console.warn("Usuário não autenticado");
                setLoading(false);
            }
        });
        return () => unsubscribe();
    }, []);

    function c() {
        window.document.title = "COM TODO RESPEITO: Minha conta"
    }

    useEffect(() => {
        c()
    })



    return (
        <>
            {Loading ? <Loader /> : null}
            <section className="MyAccount-Show-Page-styled">
                <Navigation />
                <main className="MainPageSelected">
                    <h1>Meus pedidos</h1>
                    <p>Gerencie todos os pedidos realizados na sua conta.</p>
                    <div className="MainOrdersUser">
                        {userLogged ?
                            <>
                                {usersOrders && usersOrders.map((order) => {

                                    const client = getCurrentUser(order);

                                    return (

                                        <div className="Order--item" key={`orderid-${order.id}`}>
                                            <Link reloadDocument to={`/accounts/myaccount/orders/${order.id}`}>
                                                <li className="UserOrders_ContentOrder">
                                                    <div className="OrderContent--photo">
                                                        {order && <img src={""} alt="Preview" />}
                                                    </div>
                                                    <div className="UserOrders_ContentOrder--item-wrapper">
                                                        <div className="situationtop">
                                                            <label className="Order_State">
                                                                {order.estado === "Transporte"
                                                                    ?
                                                                    <span id="produtoacaminho"><i className="fa-solid fa-truck-fast"></i> O pedido está a caminho</span>
                                                                    :
                                                                    <>
                                                                        {order.estado == "Finalizado"
                                                                            ?
                                                                            <span id="produtofinalizado"><i className="fa-solid fa-check"></i> Pedido finalizado</span>
                                                                            :
                                                                            <span><i className="fa-solid fa-box"></i> {order.estado}</span>
                                                                        }
                                                                    </>}
                                                            </label>
                                                        </div>
                                                        <p>ID DO PEDIDO: #{order.id}</p>
                                                        <div className="OrderContent-iitem-rightside">
                                                            <p>{client.nome_completo}</p>
                                                            <p>TOTAL: R${order.totalprice}</p>
                                                        </div>
                                                        <div className="btns-wrapper-button">
                                                            <div className="OrderBtn-Wrap">
                                                                <button className="ButtonWrapper">
                                                                    <span>AJUDA</span>
                                                                </button>
                                                            </div>
                                                            <div className="OrderBtn-Wrap">
                                                                <button className="ButtonWrapper">
                                                                    <span>DETALHES</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </Link>
                                        </div>
                                    )
                                })}
                            </>
                            :
                            null
                        }
                    </div>
                </main>
            </section>
        </>
    )
}