
/**
 * Creation Date: 26/04/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { account, databases } from "../../../lib/appwrite";
import Navigation from "../../../components/accounts/Navigation";
import Loader from "../../../components/Loading";
import { getCurrentUser } from "../../../lib/database";
import { auth } from "../../../lib/firebase";

export default function MyAccount_Help() {

    const [userLogged, setUserlogged] = useState(null)
    const [Loading, setLoading] = useState(false)

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    const data = await getCurrentUser(user.uid);
                    setUserlogged(data)
                } catch (error) {
                    console.warn("Erro ao obter dados do usuário: ", error);
                } finally {
                    setLoading(false);
                }
            } else {
                console.warn("Usuário não autenticado");
                setLoading(false);
            }
        });
        return () => unsubscribe();
    }, []);

    function c() {
        window.document.title = "COM TODO RESPEITO: Minha conta"
    }

    useEffect(() => {
        c()
    })



    return (
        <>
            {Loading ? <Loader /> : null}
            <section className="MyAccount-Show-Page-styled">
                <Navigation />
                <main className="MainPageSelected">
                    <h1>Ajuda</h1>
                    <p>Precisa de ajuda? Entre em contato de acordo com as opções abaixo.</p>
                    <div className="Container-Focus-MyAccount">
                        <div>
                            <Link reloadDocument to={"https://wa.me/553599553467"} target="_blank">
                                <div className="optionContainerHelp">
                                    <span>Atendimento por WhatsApp (13:00 até 17:30)</span>
                                </div>
                            </Link>
                            <Link reloadDocument to={"mailto:contato.comtodorespeito@gmail.com"} target="_blank">
                                <div className="optionContainerHelp">
                                    <span>Atendimento via Email</span>
                                </div>
                            </Link>
                            <Link reloadDocument to={"https://www.instagram.com/oficial.comtodorespeito/"} target="_blank">
                                <div className="optionContainerHelp">
                                    <span>Atendimento via Direct (Instagram)</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </main>
            </section>
        </>
    )
}