/**
 * Creation Date: 25/04/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import React from "react";
import Footer from "../../../components/mainpage/Footer";

const PoliticaDevolucao = () => {
    return (
        <React.Fragment>
            <div className="politicas-Card">
                <h1>Política de Troca e Devolução da Com Todo Respeito Inc.</h1>
                <h2>1. Introdução</h2>
                <p>A Com Todo Respeito Inc. está comprometida em garantir a satisfação e a felicidade de seus clientes. Por isso, estabelecemos uma política de troca e devolução que respeita os direitos do consumidor, seguindo as normativas vigentes no Código de Defesa do Consumidor, sempre visando a transparência e a agilidade no processo.</p>

                <h2>2. Direito de Arrependimento</h2>
                <p>De acordo com o Código de Defesa do Consumidor, o cliente que realiza compras através de lojas online tem o direito de se arrepender da compra no prazo de 7 (sete) dias corridos, a contar da data do recebimento do produto. Dentro deste prazo, o cliente pode solicitar a devolução do produto, sem necessidade de justificar o motivo para tal e sem custo de devolução, desde que o produto não tenha sido utilizado e esteja em sua embalagem original, sem violação, acompanhado de todos os acessórios.</p>

                <h2>3. Condições para Troca e Devolução</h2>
                <p>Para trocar ou devolver um produto, as seguintes condições devem ser observadas:</p>

                <p>
                    <ul>
                        <li>O prazo para solicitação de troca ou devolução é de até 7 (sete) dias corridos após o recebimento do produto.</li>
                        <li>O produto deve ser devolvido em sua embalagem original, sem indícios de uso, sem violação do lacre original do fabricante, acompanhado de nota fiscal, manual e todos os seus acessórios.</li>
                        <li>Após a solicitação de devolução, o cliente receberá um código de postagem para que possa devolver o produto sem custos de envio.</li>
                    </ul>
                </p>

                <h2>4. Processo de Troca</h2>
                <p>
                    <ul>
                        <li>A troca por outro produto do mesmo valor pode ser realizada, respeitando o prazo de 7 (sete) dias para a solicitação.</li>
                        <li>Caso o produto desejado para troca tenha valor superior, o cliente deverá providenciar o pagamento da diferença.</li>
                        <li>Produtos em promoção não poderão ser objeto de troca, exceto em casos de defeito técnico ou de fabricação.</li>
                    </ul>
                </p>

                <h2>5. Processo de Devolução por Arrependimento</h2>
                <p>
                    <ul>
                        <li>O cliente deve solicitar a devolução através do nosso site ou serviço de atendimento ao cliente, informando o número do pedido e o motivo da devolução.</li>
                        <li>Uma vez aprovada a devolução, o cliente receberá um código de postagem para envio do produto.</li>
                        <li>O reembolso será processado após o recebimento e análise das condições do produto devolvido. O valor será restituído de acordo com o meio de pagamento utilizado na compra, dentro do prazo de até 7 (sete) dias úteis.</li>
                    </ul>
                </p>

                <h2>6. Defeitos ou Avarias</h2>
                <p>
                    <ul>
                        <li>
                            Caso o produto adquirido apresente defeito ou avaria, o cliente deve comunicar nossa equipe de atendimento ao cliente imediatamente após o recebimento do produto, respeitando o prazo máximo de 7 (sete) dias corridos.
                        </li>
                        <li>
                            Será realizada uma avaliação para constatação do defeito ou avaria. Caso confirmado, o cliente poderá optar pela troca do produto por outro igual, pela devolução do valor pago, ou por um crédito no valor do produto para utilização no site.
                        </li>
                    </ul>
                </p>


                <h2>7. Considerações Finais</h2>

                <p>
                    <ul>
                        <li>A Com Todo Respeito Inc. reserva-se o direito de alterar esta política sem aviso prévio.</li>
                        <li>Dúvidas relacionadas à política de trocas e devoluções podem ser esclarecidas por meio do nosso serviço de atendimento ao cliente.</li>
                    </ul>
                </p>
                <p>A Com Todo Respeito Inc. agradece a preferência e se compromete em continuar oferecendo produtos de qualidade com o respeito que você merece.</p>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default PoliticaDevolucao;