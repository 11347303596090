/**
 * Creation Date: 17/08/2024
 * Author: Vinícius da Silva Santos
 * Developed by: ComTodoRespeitoInc.
 * Copyright 2024, ComTodoRespeitoInc.
 * All rights are reserved. Reproduction in whole or part is prohibited without the written consent of the copyright owner.
 */

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Navigation from "../../../components/accounts/Navigation";
import { ID, Query } from "appwrite";
import axios from 'axios';
import Swal from "sweetalert2";
import Loader from "../../../components/Loading";
import { getAddressUsers, getCurrentUser } from "../../../lib/database";
import { account } from "../../../lib/appwrite";
import { auth } from "../../../lib/firebase";

const endpoint = process.env.REACT_APP_API_ENDPOINT;
//const endpoint = process.env.REACT_APP_API_ENDPOINT_TEST;
const secretKey = process.env.REACT_APP_API_SECRET_KEY;

export default function MyAccount_Adress() {
    const [userLogged, setUserlogged] = useState(null);
    const [Loading, setLoading] = useState(false);

    //ENDERECOS OUTPUT

    const [enderecosOutPut, setenderecosOutPut] = useState([]);
    const [QtdEnd, setQtdEnd] = useState(0)

    //ENDEREÇO CONSTS

    const [CEP, setCEP] = useState(null);
    const [Endereco, setEndereco] = useState(null);
    const [Numero, setNumero] = useState(null);
    const [ComplementoERef, setComplementoERef] = useState(null);
    const [Estado, setEstado] = useState(null);
    const [Cidade, setCidade] = useState(null);
    const [Bairro, setBairro] = useState(null);
    const [Destinatario, setDestinatario] = useState(null);

    const [boxAdress, setBoxAdress] = useState(false)

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    const data = await getCurrentUser(user.uid);
                    setUserlogged(data)
                } catch (error) {
                    console.warn("Erro ao obter dados do usuário: ", error);
                } finally {
                    setLoading(false);
                }
            } else {
                console.warn("Usuário não autenticado");
                setLoading(false);
            }
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {

        e()

    }, [userLogged])


    async function e() {

        async function d(index) {
            const ad = await getAddressUsers(userLogged.uid) || [];
            const AddressUser = JSON.parse(ad.adress);

            let newArray = AddressUser.filter((_, i) => i !== index);

            await fetch(`${endpoint}/api/v1/${secretKey}/adress/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    uid: userLogged.uid,
                    adress: JSON.stringify(
                        newArray
                    )
                }),
            })
            e()
        }

        if (userLogged) {
            const ad = await getAddressUsers(userLogged.uid) || [];
            const AddressUser = JSON.parse(ad.adress);

            setQtdEnd(AddressUser ? AddressUser.length : 0)
            setenderecosOutPut(
                AddressUser && AddressUser.map((e, i) => {

                    return (
                        <li className="EnderecoWrapper--item">
                            <div className="EnderecoWrapper-inside">
                                <p><i className="fa-solid fa-house"></i> {e.endereco}</p>
                                <p>{e.bairro} - {e.cidade} - {e.estado}</p>
                            </div>
                            <button onClick={() => { d(i) }}><span><i className="fa-regular fa-trash-can"></i></span></button>
                        </li>
                    )
                })
            )
            /*await databases.listDocuments(
                "65490ef281a42a311fd4",
                "654efe731ff2d6c66cb8",
                [
                    Query.equal("USER_UID_RES_END", userLogged.id)
                ]
            )
                .then((r) => {
                    async function d(ID) {
                        Swal.fire({
                            title: "Você tem certeza?",
                            text: "Não é possível reverter isso!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "black",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Excluir!",
                            cancelButtonText: "Cancelar"
                        }).then((result) => {
                            if (result.isConfirmed) {
                                try {
                                    databases.deleteDocument(
                                        "65490ef281a42a311fd4",
                                        "654efe731ff2d6c66cb8",
                                        ID
                                    )
                                        .then((sucess) => {
                                            Swal.fire({
                                                title: "Excluído!",
                                                text: "O endereço foi removido.",
                                                icon: "success"
                                            })
                                            e()
                                        })
                                }
                                catch (error) {
                                    console.log(error)
                                }

                            }
                        });

                    }
                    setQtdEnd(r.documents.length)
                    setenderecosOutPut(
                        r.documents.map((e) => {

                            return (
                                <li className="EnderecoWrapper--item">
                                    <div className="EnderecoWrapper-inside">
                                        <p><i className="fa-solid fa-house"></i> {e.endereco}</p>
                                        <p>{e.bairro} - {e.cidade} - {e.estado}</p>
                                    </div>
                                    <button onClick={() => { d(e.$id) }}><span><i className="fa-regular fa-trash-can"></i></span></button>
                                </li>
                            )
                        })
                    )
                })*/
        }
    }

    async function gG(C) {
        setLoading(true)
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${C}/json/`);

            setEndereco(response.data.logradouro);
            setBairro(response.data.bairro);
            setEstado(response.data.uf);
            setCidade(response.data.localidade);
            setCEP(C)
            setLoading(false)
        } catch (error) {
            if (C) {
                alert("CEP NÃO ENCONTRADO")
            }
            setLoading(false)
        }
    }

    async function Cr_ad() {
        if (!CEP || !Endereco || !Numero || !ComplementoERef || !Estado || !Cidade || !Bairro || !Destinatario || !userLogged) {
            return
        }
        const ad = await getAddressUsers(userLogged.uid) || [];
        const AddressUser = JSON.parse(ad.adress);
        setLoading(true)
        try {

            if (AddressUser.length == 0) {

                await fetch(`${endpoint}/api/v1/${secretKey}/adress/update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        uid: userLogged.uid,
                        adress: JSON.stringify(
                            [{
                                cep: CEP,
                                endereco: Endereco,
                                numero: Numero,
                                complemento_e_ref: ComplementoERef,
                                estado: Estado,
                                cidade: Cidade,
                                bairro: Bairro,
                                destinatario: Destinatario,
                                USER_UID_RES_END: userLogged.uid
                            }]
                        )
                    }),
                })

                    .then((sucess) => {
                        setLoading(false)
                        Swal.fire({
                            position: "center",
                            icon: "success",
                            title: "Você adicionou um novo endereço.",
                            showConfirmButton: false,
                            timer: 1500
                        })
                            .then((after) => {
                                e()
                            })
                    })

            }
            else if (AddressUser >= 5) {
                return
            }
            else {

                let Address = AddressUser;

                Address.push({
                    cep: CEP,
                    endereco: Endereco,
                    numero: Numero,
                    complemento_e_ref: ComplementoERef,
                    estado: Estado,
                    cidade: Cidade,
                    bairro: Bairro,
                    destinatario: Destinatario,
                    USER_UID_RES_END: userLogged.uid
                })

                if (CEP && Endereco && Numero && ComplementoERef && Estado && Cidade && Bairro && Destinatario && userLogged) {
                    await fetch(`${endpoint}/api/v1/${secretKey}/adress/update`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            uid: userLogged.uid,
                            adress: JSON.stringify(
                                Address
                            )
                        }),
                    })

                        .then((sucess) => {
                            setLoading(false)
                            Swal.fire({
                                position: "center",
                                icon: "success",
                                title: "Você adicionou um novo endereço.",
                                showConfirmButton: false,
                                timer: 1500
                            })
                                .then((after) => {
                                    e()
                                })
                        })
                }
            }




        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    function c() {
        window.document.title = "COM TODO RESPEITO: Meus Endereços"
    }

    useEffect(() => {
        c()
    })

    return (
        <>
            {Loading ? <Loader /> : null}
            <section className="MyAccount-Show-Page-styled">
                <Navigation />
                <main className="MainPageSelected">
                    <h1>Endereços ({QtdEnd})</h1>
                    <p>Gerencie seus endereços.</p>
                    {QtdEnd >= 4
                        ?
                        <></>
                        :
                        <>
                            {
                                boxAdress
                                    ?
                                    <div className="cancelAdress-InnerWrapper-Button">
                                        <button onClick={() => { setBoxAdress(false) }}>
                                            <span>CANCELAR</span>
                                        </button>
                                    </div>
                                    :
                                    <div className="createNewAdress-InnerWrapper-Button">
                                        <button onClick={() => { setBoxAdress(true) }}>
                                            <span>ADICIONAR ENDEREÇO</span>
                                        </button>
                                    </div>
                            }
                        </>
                    }

                    {boxAdress ?
                        <>
                            {QtdEnd >= 4 ?
                                <></>
                                :
                                <div className="CreateNewAddress">
                                    <div className="Inputs-Content">
                                        <div className="LeftSide">
                                            <div className="CheckoutPage-InputBox">
                                                <p>CEP</p>
                                                <input onBlur={(e) => { gG(e.target.value) }} type="text" />
                                            </div>
                                            <div className="CheckoutPage-InputBox">
                                                <p>Endereço</p>
                                                <input onChange={(e) => { setEndereco(e.target.value) }} value={Endereco} type="text" />
                                            </div>
                                            <div className="CheckoutPage-InputBox">
                                                <p>Número</p>
                                                <input onChange={(e) => { setNumero(e.target.value) }} type="text" />
                                            </div>
                                            <div className="CheckoutPage-InputBox">
                                                <p>Complemento e referência</p>
                                                <input onChange={(e) => { setComplementoERef(e.target.value) }} type="text" />
                                            </div>
                                        </div>
                                        <div className="RightSide">
                                            <div className="CheckoutPage-InputBox">
                                                <p>Estado</p>
                                                <input onChange={(e) => { setEstado(e.target.value) }} value={Estado} type="text" />
                                            </div>
                                            <div className="CheckoutPage-InputBox">
                                                <p>Cidade</p>
                                                <input onChange={(e) => { setCidade(e.target.value) }} value={Cidade} type="text" />
                                            </div>
                                            <div className="CheckoutPage-InputBox">
                                                <p>Bairro</p>
                                                <input onChange={(e) => { setBairro(e.target.value) }} value={Bairro} type="text" />
                                            </div>
                                            <div className="CheckoutPage-InputBox">
                                                <p>Destinatário</p>
                                                <input onChange={(e) => { setDestinatario(e.target.value) }} type="text" />
                                            </div>
                                            <div className="CheckoutPage-CreateAddress createNewAdress-InnerWrapper-Button ">
                                                <button onClick={Cr_ad}>
                                                    <span>ADICIONAR NOVO ENDEREÇO</span>
                                                </button>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                        :
                        null
                    }


                    <div className="EnderecosPage">
                        <div className="EnderecosExists">
                            <ul className="listsEnderecos-Checkout">
                                {enderecosOutPut}
                            </ul>
                        </div>
                        {QtdEnd >= 4}

                    </div>
                </main>
            </section>
        </>
    )
}